import React from "react";

const LasambusChartBar = () => {

  return (
    <div className="chartbarLasambus">
      <div className="temp"></div>
      <div className="temp"></div>
    </div>
  );
};

export default LasambusChartBar;
