import React, { useState, useEffect, useContext } from "react";
import { DataContext } from "../contexts/DataContext.jsx";
import MultiselectDropdown from "../components/MultiselectDropdown.jsx";
import SingleSelectDropdown from "../components/SingleSelectDropdown.jsx"
import { getUniqueValues, sortYears, sortQuarters, sortAlphabetically } from "../functions.js";
import TuberculosisData from "../data/TuberculosisData.jsx"

const TuberculosisSlicerBar = () => {
  const { dispatch, state } = useContext(DataContext);
  const { tuberculosisData } = state

  // Selection State
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedQuarters, setSelectedQuarters] = useState([]);
  const [selectedLGAs, setSelectedLGAs] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [selectedDatapoints, setSelectedDatapoints] = useState("Tests");

  // Display State
  const [displayYears, setDisplayYears] = useState("");
  const [displayQuarters, setDisplayQuarters] = useState("");
  const [displayLGAs, setDisplayLGAs] = useState("");
  const [displayFacilities, setDisplayFacilities] = useState("");
  const [displayDatapoints, setDisplayDatapoints] = useState("");

  // Slicers
  const [yearSelectFilter, setYearSelectFilter] = useState([]);
  const [quarterSelectFilter, setMonthSelectFilter] = useState([]);
  const [LGASelectFilter, setLGASelectFilter] = useState([]);
  const [FacilitySelectFilter, setFacilitySelectFilter] = useState([]);
  const [DatapointSelectFilter, setDatapointSelectFilter] = useState([]);

  // FILTERED DATA
  useEffect(() => {
    const newTuberculosisData = tuberculosisData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const quarterMatch =
        selectedQuarters.length === 0 || selectedQuarters.includes(row.quarter);
      const LGAMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);
      const facilityMatch =
        selectedFacilities.length === 0 ||
        selectedFacilities.includes(row.facility);

      return yearMatch && quarterMatch && LGAMatch && facilityMatch;
    });

    dispatch({
      type: "UPDATE_FILTERED_TUBERCULOSIS_DATA",
      payload: newTuberculosisData,
    });
  }, [
    selectedYears,
    selectedQuarters,
    selectedLGAs,
    selectedFacilities,
    tuberculosisData,
  ]);
  
  // Single Select Filter
  useEffect(() => {
    const newTuberculosisDatapointData = tuberculosisData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const quarterMatch =
        selectedQuarters.length === 0 || selectedQuarters.includes(row.quarter);
      const LGAMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);
      const facilityMatch =
        selectedFacilities.length === 0 ||
        selectedFacilities.includes(row.facility);
      const datapointMatch =
        selectedDatapoints.length === 0 ||
        selectedDatapoints.includes(row.datapoint);

      return yearMatch && quarterMatch && LGAMatch && facilityMatch && datapointMatch;
    });

    dispatch({
      type: "UPDATE_FILTERED_TUBERCULOSIS_DATAPOINT_DATA",
      payload: newTuberculosisDatapointData,
    });
  }, [
    selectedYears,
    selectedQuarters,
    selectedLGAs,
    selectedFacilities,
    selectedDatapoints,
    tuberculosisData,
  ]);

  // MULTISELECT FILTERS
  // Year
  useEffect(() => {
    const yearFilteredData = tuberculosisData.filter((row) => {
      const quarterMatch =
        selectedQuarters.length === 0 || selectedQuarters.includes(row.quarter);
      const lgaMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);
      const facilityMatch =
        selectedFacilities.length === 0 ||
        selectedFacilities.includes(row.facility);

      return quarterMatch && lgaMatch && facilityMatch;
    });

    setYearSelectFilter(yearFilteredData);
  }, [selectedQuarters, selectedLGAs, selectedFacilities, tuberculosisData, dispatch]);

  // Quarter
  useEffect(() => {
    const quarterFilteredData = tuberculosisData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const lgaMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);
      const facilityMatch =
        selectedFacilities.length === 0 ||
        selectedFacilities.includes(row.facility);

      return yearMatch && lgaMatch && facilityMatch;
    });

    setMonthSelectFilter(quarterFilteredData);
  }, [selectedYears, selectedLGAs, selectedFacilities, tuberculosisData, dispatch]);

  // LGA
  useEffect(() => {
    const lgaFilteredData = tuberculosisData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const quarterMatch =
        selectedQuarters.length === 0 || selectedQuarters.includes(row.quarter);
      const facilityMatch =
        selectedFacilities.length === 0 ||
        selectedFacilities.includes(row.facility);

      return yearMatch && quarterMatch && facilityMatch;
    });

    setLGASelectFilter(lgaFilteredData);
  }, [selectedYears, selectedQuarters, selectedFacilities, tuberculosisData, dispatch]);

  // Facility
  useEffect(() => {
    const quarterFilteredData = tuberculosisData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const quarterMatch =
        selectedQuarters.length === 0 || selectedQuarters.includes(row.quarter);
      const lgaMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);

      return yearMatch && quarterMatch && lgaMatch;
    });

    setFacilitySelectFilter(quarterFilteredData);
  }, [selectedYears, selectedQuarters, selectedLGAs, tuberculosisData, dispatch]);

  // Datapoint
  useEffect(() => {
    const datapointFilteredData = tuberculosisData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const quarterMatch =
        selectedQuarters.length === 0 || selectedQuarters.includes(row.quarter);
      const lgaMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);

      return yearMatch && quarterMatch && lgaMatch;
    });

    setDatapointSelectFilter(datapointFilteredData);
  }, [selectedYears, selectedQuarters, selectedLGAs, tuberculosisData, dispatch]);

  // Select Functionality
  const handleYearSelect = (selectedList) => {
    setSelectedYears(selectedList);
    setDisplayYears(`${selectedList.length} options selected`);
  };

  const handleMonthSelect = (selectedList) => {
    setSelectedQuarters(selectedList);
    setDisplayQuarters(`${selectedList.length} options selected`);
  };

  const handleLGASelect = (selectedList) => {
    setSelectedLGAs(selectedList);
    setDisplayLGAs(`${selectedList.length} options selected`);
  };

  const handleFacilitiesSelect = (selectedList) => {
    setSelectedFacilities(selectedList);
    setDisplayFacilities(`${selectedList.length} options selected`);
  };

  const handleDatapointsSelect = (selectedList) => {
    setSelectedDatapoints(selectedList);
    setDisplayDatapoints(`${selectedList.length} options selected`);
  };

  const SelectedCountDisplay = ({ selectedOptions }) => {
    const selectedCount = selectedOptions.length;
    return <div>{`${selectedCount} options selected`}</div>;
  };
  return (
    <>
      <TuberculosisData />
      <div className="slicerbarTuberculosis">
        <MultiselectDropdown
          label="Year"
          id="year"
          placeholder="Year"
          options={sortYears(getUniqueValues(yearSelectFilter, "year"))}
          selectedValues={selectedYears}
          onChange={handleYearSelect}
          multiselectClass='multiselect-dropdown'
        />
        <MultiselectDropdown
          label="Quarter"
          id="quarter"
          placeholder="Quarter"
          options={sortQuarters(getUniqueValues(quarterSelectFilter, "quarter"))}
          selectedValues={selectedQuarters}
          onChange={handleMonthSelect}
          multiselectClass='multiselect-dropdown'
        />
        <MultiselectDropdown
          label="LGA"
          id="lga"
          placeholder="Local Government Area"
          options={sortAlphabetically(getUniqueValues(LGASelectFilter, "lga"))}
          selectedValues={selectedLGAs}
          onChange={handleLGASelect}
          multiselectClass='multiselect-dropdown'
        />
        <MultiselectDropdown
          label="Health Facility"
          id="facility"
          placeholder="Health Facility"
          options={sortAlphabetically(getUniqueValues(FacilitySelectFilter, "facility"))}
          selectedValues={selectedFacilities}
          onChange={handleFacilitiesSelect}
          multiselectClass='multiselect-dropdown'
        />
        <SingleSelectDropdown
        label="Datapoint"
        id="datapoint"
        placeholder="Datapoint"
        options={sortAlphabetically(getUniqueValues(DatapointSelectFilter, "datapoint"))}
        selectedValue={selectedDatapoints}
        onChange={handleDatapointsSelect}
        dropdownClass='multiselect-dropdown'
      />
      </div>
    </>   
  )
}

export default TuberculosisSlicerBar