import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DataProvider } from "./contexts/DataContext.jsx";
import { AuthProvider } from "./contexts/AuthContext.jsx";
import { LoadingProvider } from "./contexts/LoadingContext.jsx";
import { colourScale } from "./data/colourScale.js";
import Header from "./global/Header.jsx";
import Hospitals from "./pages/Hospitals.jsx";
import Lasambus from "./pages/Lasambus.jsx";
import Programs from "./pages/Programs.jsx";
import Hiv from "./pages/Hiv.jsx";
import Epidemiology from "./pages/Epidemiology.jsx";
import Malaria from "./pages/Malaria.jsx";
import Tuberculosis from "./pages/Tuberculosis.jsx";
import "../src/styles/index.css";
import "../src/styles/icons.css";

const App = () => {
  return (
    <div className="App">
      <AuthProvider>
        <DataProvider>
          <LoadingProvider>
            <BrowserRouter>
              <Header />
              <Routes>
                <Route
                  path="/"
                  element={<Hospitals colourScale={colourScale} />}
                />
                <Route
                  path="/lasambus"
                  element={<Lasambus colourScale={colourScale} />}
                />
                <Route path="/programs" element={<Programs />} />
                <Route
                  path="/programs/hiv"
                  element={<Hiv colourScale={colourScale} />}
                />
                <Route
                  path="/programs/epi"
                  element={<Epidemiology colourScale={colourScale} />}
                />
                <Route
                  path="/programs/tuberculosis"
                  element={<Tuberculosis colourScale={colourScale} />}
                />
                <Route path="/programs/malaria" element={<Malaria />} />
              </Routes>
            </BrowserRouter>
          </LoadingProvider>
        </DataProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
