import React from 'react'
import ProgramItem from '../components/ProgramItem'


const Programs = () => {

  return (
    <div className='programsmenu'>
      <ProgramItem route={"/programs/malaria"} item='Malaria' backgroundColor='grey'/>
      <ProgramItem route={"/programs/hiv"} item='HIV' backgroundColor='grey'/>
      <ProgramItem route={"/programs/epi"} item='Epidemiology' backgroundColor='grey'/>
      <ProgramItem item='Malaria' backgroundColor='grey'/>
      <ProgramItem item='HIV' backgroundColor='grey'/>
      <ProgramItem item='Epidemiology' backgroundColor='grey'/>
    </div>
  )
}

export default Programs