import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import ChartHeader from "../components/ChartHeader.jsx";
import ColourLegend from "../components/ColourLegend.jsx";

const LineChart = ({
  colourScale,
  title,
  data,
  backgroundColour,
  colourLegend,
  legendItem,
  legendLabel,
  keys,
}) => {
  const svgRef = useRef(null);
  const parentRef = useRef(null);
  // const [legendHovered, setHoveredLegendItem] = useState(null);
  const [legendHovered, setLegendHovered] = useState(null);

  const handleLegendHover = (hoveredItem) => {
    if (hoveredItem !== legendHovered) {
      setLegendHovered(hoveredItem);
    } 
  };

  // const curveInterpolator = d3.curveCardinal;

  useEffect(() => {
    const handleResize = () => {
      if (data && svgRef.current) {
        const parentElement = parentRef.current;
        const containerWidth = parentElement.clientWidth;
        const containerHeight = parentElement.clientHeight;
  
        // Create the chart dimensions and margins
        const width = containerWidth * 0.85;
        const height = containerHeight * 0.75;
  
        // SVG Container
        const svg = d3
          .select(svgRef.current)
          .attr("class", "chart-container")
          .attr("width", width)
          .attr("height", height);

        svg.selectAll("*").remove();
  
        // Parse the Date Format
        const parseDate = d3.timeParse("%Y-%m-%d");
  
        // X and Y Scales
        const xScale = d3
        .scaleTime()
        .domain(d3.extent(data, d => parseDate(d.date)))
        .range([0, width])
  
        const yScale = d3
        .scaleLinear()
        .domain([0, d3.max(data, d => +d.value)])
        .nice()
        .range([height, 0])
  
        // Generate yAxis tick values
        const yAxisTicks = yScale.ticks(5).map((tick) => ({
          value: tick,
          position: yScale(tick),
        }));
  
        // Guideline
        svg
        .selectAll(".guideline")
        .data(yAxisTicks)
        .join(
          (enter) => enter.append("line").attr("class", "guideline"),
          (update) => update,
          (exit) => exit.remove()
        )
        .attr("x1", 0)
        .attr("y1", (tickValue) => tickValue.position)
        .attr("x2", width)
        .attr("y2", (tickValue) => tickValue.position)
        .attr("stroke", "black")
        .attr("stroke-width", 0.5);
  
        // Create a Line Generator
        const line = d3
        .line()
        .x(d => xScale(parseDate(d.date)))
        .y(d => yScale(+d.value))
        // .curve(curveInterpolator);
  
        // Create a line for each unique datapoint
        const datapoints = Array.from(new Set(data.map(d => d.datapoint)));
  
        datapoints.forEach((datapoint) => {
          const dataFiltered = data.filter(d => d.datapoint === datapoint);
          svg
          .append("path")
          .datum(dataFiltered)
          .attr("fill", "none")
          .attr("stroke", colourScale(datapoint))
          .attr("stroke-width", 1.5)
          .attr("d", line)
          .attr("opacity", (d) =>
              legendHovered === null || legendHovered === datapoint
                ? 1
                : 0.3
          )
          .on("mousemove", (event) => {
            setLegendHovered(datapoint);

            // Create a group for the label and value text and rectangle
            const tooltip = svg.append("g").attr("class", "tooltip");

            // X and Y Positions
            const [x, y] = d3.pointer(event);
            // console.log('x', event[0]);
          })
          .on("mouseout", () => {
            setLegendHovered(null);
          });
        });

        // X and Y Axes
        const xAxis = d3.axisBottom(xScale);
        svg
          .append("g")
          .attr("class", "x-axis")
          .attr("transform", `translate(0, ${height})`)
          .call(xAxis)
          .selectAll("text")
          .attr("dy", "0.5em")
          .attr("text-anchor", "middle")
          .attr("fill", "black")
          .style("font-size", "1em");
  
        const yAxis = d3.axisLeft(yScale).ticks(5);
        svg
          .append("g")
          .attr("class", "y-axis")
          .call(yAxis)
          .selectAll(".tick line")
          .attr("x1", -5)
          .attr("stroke", "gray");
        }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

  }, [data, legendHovered]);

  return (
    <div
      className="linechart"
      style={{
        backgroundColor: `${backgroundColour}`,
      }}
    >
      <ChartHeader title={title} />
      <ColourLegend
        keys={keys}
        colourScale={colourScale}
        colourLegend={colourLegend}
        legendItem={legendItem}
        legendLabel={legendLabel}
        onHover={handleLegendHover}
      />
      <svg
        ref={parentRef}
        className="chartsvg"
        style={{
          width: "100%",
          position: "relative"
        }}
      >
        <g className="lines" ref={svgRef}>
          <g className="x-axis" />
          <g className="y-axis" />
        </g>
      </svg>
    </div>
  );
};

export default LineChart;