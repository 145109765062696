import axios from "axios";

const localApi = {
  login: "http://localhost:6334/ehealth/login",
  attendanceData: "http://localhost:6334/ehealth/attendance",
  deliveriesData: "http://localhost:6334/ehealth/deliveries",
  maternityData: "http://localhost:6334/ehealth/maternity",
  operationsData: "http://localhost:6334/ehealth/operations",
  rtaData: "http://localhost:6334/ehealth/rta",
  slicersData: "http://localhost:6334/ehealth/slicers",
  usersData: "http://localhost:6334/ehealth/users",
};

const prodApi = {
  login: "https://data.6334group.com/ehealth/login",
  user: "https://data.6334group.com/ehealth/user",

  // Health Facilities
  hospitalAttendanceData: "https://data.6334group.com/ehealth/attendance",
  hospitalDeliveriesData: "https://data.6334group.com/ehealth/deliveries",
  hospitalMaternityData: "https://data.6334group.com/ehealth/maternity",
  hospitalOperationsData: "https://data.6334group.com/ehealth/operations",
  hospitalRtaData: "https://data.6334group.com/ehealth/rta",
  hospitalSlicersData: "https://data.6334group.com/ehealth/slicers",

  // HIV
  hivData: "https://data.6334group.com/ehealth/hiv",

  // Epidemiology
  epiData: "https://data.6334group.com/ehealth/epid",

  // Tuberculosis
  tuberculosisData: "https://data.6334group.com/ehealth/tuberculosis",

  // Lasambus
  // Malaria
};

const api = axios.create({
  baseURL: "http://localhost:6334/ehealth",
});

const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common["authorization"] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common["authorization"];
  }
};

export { prodApi, setAuthToken };
