import React from "react";

const LasambusChartBar2 = () => {

  return (
    <div className="chartbarLasambus2">
      <div className="temp"></div>
      <div className="temp"></div>
      <div className="temp"></div>
    </div>
  );
};

export default LasambusChartBar2;
