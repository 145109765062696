import React, { useState, useEffect, useRef } from "react";

const SingleSelectDropdown = ({
  id,
  label,
  options,
  placeholder,
  onChange,
  selectedValue,
  dropdownClass,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("mousedown", handleMouseDown);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className={dropdownClass} ref={dropdownRef}>
      <label htmlFor={id}>{label}</label>
      <div className="dropdown">
        <div className="selected-values" onClick={handleToggle}>
          <div className="selected-text">
            {selectedValue || placeholder}
          </div>
          <div className="dropdown-arrow">&#9662;</div>
        </div>
        {isOpen && (
          <div className="options">
            <ul>
              {options.map((option) => (
                <li key={option} onClick={() => handleOptionClick(option)}>
                <input
                  type="checkbox"
                  checked={selectedValue === option}
                  readOnly
                />
                {option}
              </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleSelectDropdown;
