import React, { createContext, useReducer } from "react";

const initialState = {
  // Hospitals
  slicers: [],
  attendanceData: [],
  deliveriesData: [],
  maternityData: [],
  operationsData: [],
  rtaData: [],
  filteredAttendanceData: [],
  filteredDeliveriesData: [],
  filteredMaternityData: [],
  filteredOperationsData: [],
  filteredRtaData: [],

  // HIV
  hivData: [],
  filteredHivData: [],

  // Epidemiology
  epiData: [],
  filteredEpiData: [],
  filteredEpiDiseaseData: [],

  // Tuberculosis
  tuberculosisData: [],
  filteredTuberculosisData: [],
  filteredTuberculosisDatapointData: [],
};

export const DataContext = createContext(initialState);

const reducer = (state, action) => {
  // HEALTH FACILITIES DATA
  switch (action.type) {
    case "SLICERS":
      return {
        ...state,
        slicers: action.payload,
      };
    case "ATTENDANCE_DATA":
      return {
        ...state,
        attendanceData: action.payload,
      };
    case "DELIVERIES_DATA":
      return {
        ...state,
        deliveriesData: action.payload,
      };
    case "MATERNITY_DATA":
      return {
        ...state,
        maternityData: action.payload,
      };
    case "OPERATIONS_DATA":
      return {
        ...state,
        operationsData: action.payload,
      };
    case "RTA_DATA":
      return {
        ...state,
        rtaData: action.payload,
      };
  
  // Health Facilities Filtered Data
      case "UPDATE_FILTERED_ATTENDANCE_DATA":
      return {
        ...state,
        filteredAttendanceData: action.payload,
      };
    case "UPDATE_FILTERED_DELIVERIES_DATA":
      return {
        ...state,
        filteredDeliveriesData: action.payload,
      };
    case "UPDATE_FILTERED_MATERNITY_DATA":
      return {
        ...state,
        filteredMaternityData: action.payload,
      };
    case "UPDATE_FILTERED_OPERATIONS_DATA":
      return {
        ...state,
        filteredOperationsData: action.payload,
      };
    case "UPDATE_FILTERED_RTA_DATA":
      return {
        ...state,
        filteredRtaData: action.payload,
      };
  
  // HIV
    case "HIV_DATA":
      return {
        ...state,
        hivData: action.payload,
      };

  // HIV Filtered Data
    case "UPDATE_FILTERED_HIV_DATA":
      return {
        ...state,
        filteredHivData: action.payload,
      };

  // Epidemiology
    case "EPI_DATA":
      return {
        ...state,
        epiData: action.payload,
      };

  // Epidemiology Filtered Data
    case "UPDATE_FILTERED_EPI_DATA":
      return {
        ...state,
        filteredEpiData: action.payload,
      };

    case "UPDATE_FILTERED_EPI_DISEASE_DATA":
      return {
        ...state,
        filteredEpiDiseaseData: action.payload,
      };

  // Tuberculosis 
    case "TUBERCULOSIS_DATA":
      return {
        ...state,
        tuberculosisData: action.payload,
      };

  // Tuberculosis Filtered Data
    case "UPDATE_FILTERED_TUBERCULOSIS_DATA":
      return {
        ...state,
        filteredTuberculosisData: action.payload,
      };
    case "UPDATE_FILTERED_TUBERCULOSIS_DATAPOINT_DATA":
      return {
        ...state,
        filteredTuberculosisDatapointData: action.payload,
      };
    default:
      return state;
  }
};

export const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};
