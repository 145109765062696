import React, { useState, useEffect, useContext } from "react";
import { DataContext } from "../contexts/DataContext.jsx";
import { fetchInitData } from "../functions.js";
import { prodApi } from "./api.js";
import { useLoading } from "../contexts/LoadingContext.jsx";

// URLs
const urlSlicers = prodApi.hospitalSlicersData;
const urlAttendance = prodApi.hospitalAttendanceData;
const urlDeliveries = prodApi.hospitalDeliveriesData;
const urlMaternity = prodApi.hospitalMaternityData;
const urlOperations = prodApi.hospitalOperationsData;
const urlRta = prodApi.hospitalRtaData;

// Full Data
const HospitalsData = () => {
  const { dispatch } = useContext(DataContext);
  const { startLoading, stopLoading } = useLoading()

  const [slicers, setSlicers] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [deliveriesData, setDeliveriesData] = useState([]);
  const [maternityData, setMaternityData] = useState([]);
  const [operationsData, setOperationsData] = useState([]);
  const [rtaData, setRtaData] = useState([]);

  // FETCH AND SET DATA
  useEffect(() => {
    startLoading();
    fetchInitData(urlSlicers, setSlicers, 'hospital');
    fetchInitData(urlAttendance, setAttendanceData, 'hospital')
    .then(() => {
      stopLoading();
    })
    .catch(() => {
      stopLoading();
    });
    fetchInitData(urlDeliveries, setDeliveriesData, 'hospital');
    fetchInitData(urlMaternity, setMaternityData, 'hospital');
    fetchInitData(urlOperations, setOperationsData, 'hospital');
    fetchInitData(urlRta, setRtaData, 'hospital');
}, []);

useEffect(() => {
    dispatch({
        type: "SLICERS",
        payload: slicers,
      });
    dispatch({
      type: "ATTENDANCE_DATA",
      payload: attendanceData,
    });
    dispatch({
        type: "DELIVERIES_DATA",
        payload: deliveriesData,
    });
    dispatch({
        type: "MATERNITY_DATA",
        payload: maternityData,
    });
    dispatch({
        type: "OPERATIONS_DATA",
        payload: operationsData,
    });
    dispatch({
        type: "RTA_DATA",
        payload: rtaData,
    });
},[
    slicers,
    attendanceData, 
    deliveriesData, 
    maternityData, 
    operationsData, 
    rtaData
])

  return null;
};

export default HospitalsData;