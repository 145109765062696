import React, { useContext, useEffect, useState } from 'react';
import * as d3 from "d3";
import * as topojson from "topojson-client"; 
import { DataContext } from '../contexts/DataContext.jsx';
import PieChart from '../charts/PieChart.jsx'
import MapChart from '../charts/MapChart.jsx'
import { getUniqueValues, pieDeliveries2, extractLabels } from '../functions.js';
import lgLGAs from "../data/lgLGAs.topojson"

const HivChartBar2 = ({colourScale}) => {
  const { state } = useContext(DataContext);
  const { filteredHivData } = state;

  const [mapData, setMapData] = useState(null)

  // Set LGA Data
  useEffect(() => {
    d3.json(lgLGAs).then(topojsonData => {
      const {LGAs} = topojsonData.objects;
      setMapData(topojson.feature(topojsonData, LGAs))
    })
  }, [])

  // Pie Chart (Age Distribution)
  const ageData = getUniqueValues(filteredHivData, 'age')
  const agePieData = pieDeliveries2(filteredHivData, ageData, 'age');
  const ageKey = extractLabels(agePieData);
  const ageTitle = "Age Group Distribution";

  // Pie Chart (Gender Distribution)
  const genderData = getUniqueValues(filteredHivData, 'gender')
  const genderPieData = pieDeliveries2(filteredHivData, genderData, 'gender');
  const genderKey = extractLabels(genderPieData);
  const genderTitle = "Gender Distribution";

  const PieChartMargin = 5;
  const PieChartOffSetCentre = 0;

  // Map Chart
  const mapTitle = ""
  const mapColour = "#74B72E"
  const mapHoveredColour = "#466D1D"

  const grey = "rgba(0,0,0,0.1)"
  return (
    <div className='hivchartbar2'>
      <PieChart
        title={ageTitle}
        data={agePieData}
        colourScale={colourScale}
        colourLegend="color-legend-hidden"
        legendItem="legend-item-hidden"
        legendLabel="legend-label-hidden"
        keys={ageKey}
        margin={PieChartMargin}
        backgroundColour={grey}
        offset={PieChartOffSetCentre}
      />
      <PieChart
        title={genderTitle}
        data={genderPieData}
        colourScale={colourScale}
        colourLegend="color-legend-hidden"
        legendItem="legend-item-hidden"
        legendLabel="legend-label-hidden"
        keys={genderKey}
        margin={PieChartMargin}
        backgroundColour={grey}
        offset={PieChartOffSetCentre}
      />
      <MapChart
        title={mapTitle}
        backgroundColour={grey}
        data={filteredHivData}
        mapData={mapData}
        mapColour={mapColour}
        mapHoveredColour={mapHoveredColour}
        sub='lga'
        minColour='white'
        maxColour="#960018"
      />
    </div>
    
  )
}

export default HivChartBar2