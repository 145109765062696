import axios from "axios";
import * as d3 from "d3";

// Addition Function
const sumValuesByDataPoint = (data, datapoint) => {
  let sum = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].datapoint === datapoint) {
      sum += parseInt(data[i].value);
    }
  }
  return sum;
};

const sumValuesByData = (data, datapoint) => {
  let sum = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].disease === datapoint) {
      sum += parseInt(data[i].value);
    }
  }
  return sum;
};

const sumValuesByDiseaseAndDatapoint = (data, disease, datapoint) => {
  let total = 0;

  data.forEach((entry) => {
    if (entry.disease === disease && entry.datapoint === datapoint) {
      total += parseInt(entry.value, 10);
    }
  });

  return total;
};

// Thousand and Million Format
const formatNumber = (number) => {
  if (number >= 1000000 && number < 1000000000) {
    return (number / 1000000).toFixed(2) + "M";
  } else if (number >= 1000 && number < 1000000) {
    return (number / 1000).toFixed(2) + "K";
  } else {
    return number.toString();
  }
};

// Unique Options Function
const getUniqueValues = (array, key) => {
  return [...new Set(array.map((item) => item[key]))];
};

// Multibar Chart Separation Logic
const processData = (data) => {
  data.sort((a, b) => a.year - b.year);

  const uniqueHospital = [...new Set(data.map((item) => item.hospital))];
  const uniqueYear = [...new Set(data.map((item) => item.year))];
  const hospitalValues = {};

  data.forEach((item) => {
    const { hospital, year, value } = item;
    const newValue = +value;

    if (hospitalValues[hospital]) {
      hospitalValues[hospital][year] =
        (hospitalValues[hospital][year] || 0) + newValue;
    } else {
      hospitalValues[hospital] = { [year]: newValue };
    }
  });

  const attendanceValues = Array.from(uniqueYear).map((year) => {
    const values = Object.values(hospitalValues).map(
      (years) => years[year] || 0
    );
    return values;
  });

  const result = [
    Array.from(uniqueHospital),
    Array.from(uniqueYear),
    attendanceValues,
  ];

  return result;
};

const processMBCData = (data) => {
  // data.sort((a, b) => b.value - a.value);

  const uniqueLGA = [...new Set(data.map((item) => item.lga))];
  const uniqueYear = [...new Set(data.map((item) => item.year))];
  const lgaValues = {};

  data.forEach((item) => {
    const { lga, year, value } = item;
    const newValue = +value;

    if (lgaValues[lga]) {
      lgaValues[lga][year] = (lgaValues[lga][year] || 0) + newValue;
    } else {
      lgaValues[lga] = { [year]: newValue };
    }
  });

  const hivValues = Array.from(uniqueYear).map((year) => {
    const values = Object.values(lgaValues).map((years) => years[year] || 0);
    return values;
  });

  const result = [Array.from(uniqueLGA), Array.from(uniqueYear), hivValues];

  return result;
};

const extractLabels = (data) => {
  return data.map((item) => item.label);
};

const fetchData = async (url, setData, setFilteredData) => {
  try {
    const res = await axios.get(url);
    let data = res.data;

    // Sort the data by date
    data.sort((a, b) => new Date(a.date) - new Date(b.date));

    setData(
      data.map((item) => ({
        ...item,
        year: new Date(item.date).getFullYear(),
        month: new Date(item.date).toLocaleString("default", {
          month: "short",
        }),
      }))
    );
    setFilteredData(data);
  } catch (err) {
    console.error("Error retrieving data:", err);
  }
};

const fetchInitData = async (url, setData, sortBy) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let data = await res.data;

    // Sort the data by date
    data.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Then, sort the data by LGA in alphabetical order
    data.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));

    setData(
      data.map((item) => {
        const date = new Date(item.date);
        const year = date.getFullYear();
        const month = date.getMonth();
        const quarter = getQuarter(month);

        return {
          ...item,
          year,
          month: new Date(item.date).toLocaleString("default", {
            month: "short",
          }),
          quarter: quarter,
        };
      })
    );
  } catch (err) {
    console.error("Error retrieving data:", err);
    throw err;
  }
};

// Helper function to get the quarter based on the month
const getQuarter = (month) => {
  if (month >= 0 && month <= 2) {
    return "First";
  } else if (month >= 3 && month <= 5) {
    return "Second";
  } else if (month >= 6 && month <= 8) {
    return "Third";
  } else {
    return "Fourth";
  }
};

function sumDeliveries(data, deliveryTypes) {
  // Filter the data to include only the relevant delivery types
  const filteredData = data.filter((item) =>
    deliveryTypes.includes(item.datapoint)
  );

  // Group the data by delivery type and calculate the sum of values for each group
  const groupedData = filteredData.reduce((acc, item) => {
    if (item.datapoint === "C/Section") {
      // Special case for "C/Section" since it is a separate category
      acc["C/Section"] = (acc["C/Section"] || 0) + parseInt(item.value);
    } else {
      // Group all other types under "Vaginal Deliveries"
      acc["Vaginal Deliveries"] =
        (acc["Vaginal Deliveries"] || 0) + parseInt(item.value);
    }
    return acc;
  }, {});

  // Convert the grouped data into the desired result format
  const result = Object.entries(groupedData).map(([label, value]) => ({
    label,
    value,
  }));

  return result;
}

const pieDeliveries = (data, datapoints) => {
  // Initialize an object to store the sums for each datapoint
  const groupedData = {};

  // Calculate the sum of values for each datapoint
  data.forEach((item) => {
    if (datapoints.includes(item.datapoint)) {
      groupedData[item.datapoint] =
        (groupedData[item.datapoint] || 0) + parseInt(item.value);
    }
  });

  // Convert the grouped data into the desired result format
  const result = Object.entries(groupedData).map(([label, value]) => ({
    label,
    value,
  }));

  return result;
};

const pieDeliveries2 = (data, datapoints, datapoint) => {
  // Initialize an object to store the sums for each datapoint
  const groupedData = {};

  // Calculate the sum of values for each datapoint
  data.forEach((item) => {
    if (datapoints.includes(item[datapoint])) {
      groupedData[item[datapoint]] =
        (groupedData[item[datapoint]] || 0) + parseInt(item.value);
    }
  });

  // Convert the grouped data into the desired result format
  const result = Object.entries(groupedData).map(([label, value]) => ({
    label,
    value,
  }));

  return result;
};

const sortYears = (years) => {
  return years.sort((a, b) => a - b);
};

const sortMonths = (monthsArray) => {
  // Define an object to map month names to their numeric values
  const monthToNumber = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };

  // Sort the array based on the numeric values of the months
  monthsArray.sort((a, b) => monthToNumber[a] - monthToNumber[b]);

  return monthsArray;
};

const sortQuarters = (quarterArray) => {
  // Define an object to map month names to their numeric values
  const quarterToNumber = {
    First: 1,
    Second: 2,
    Third: 3,
    Fourth: 4,
  };

  // Sort the array based on the numeric values of the months
  quarterArray.sort((a, b) => quarterToNumber[a] - quarterToNumber[b]);

  return quarterArray;
};

const sortAlphabetically = (arr) => {
  // Use the sort method with a comparison function to sort alphabetically
  arr.sort(function (a, b) {
    return a.localeCompare(b);
  });

  return arr;
};

const sortAgeRanges = (arr) => {
  arr.sort(function (a, b) {
    const rangeA = a.split("-");
    const rangeB = b.split("-");
    const startA = parseInt(rangeA[0]);
    const startB = parseInt(rangeB[0]);

    if (startA < startB) {
      return -1;
    } else if (startA > startB) {
      return 1;
    } else {
      // If the start values are the same, compare the end values
      const endA = parseInt(rangeA[1]);
      const endB = parseInt(rangeB[1]);
      return endA - endB;
    }
  });

  return arr;
};

const filterDataset = (data, selectedDatapoints, datapoint) => {
  return data.filter((row) => selectedDatapoints.includes(row[datapoint]));
};

const aggregateValuesByState = (data) => {
  const stateMap = {};

  data.forEach((entry) => {
    const { state, date, datapoint, value, year, month } = entry;

    if (state) {
      if (!stateMap[state]) {
        stateMap[state] = { state, date, datapoint, value: 0, year, month };
      }
      stateMap[state].value += parseFloat(value);
    }
  });

  return Object.values(stateMap);
};

const calculatePositivityRatio = (data) => {
  const result = {};

  data.forEach((entry) => {
    const { date, datapoint, value } = entry;

    if (!result[date]) {
      result[date] = {
        date,
        datapoint: "Ratio",
        positive: 0,
        negative: 0,
        value: 0,
      };
    }

    if (datapoint === "Positive") {
      result[date].positive += parseFloat(value || 0);
    } else if (datapoint === "Negative") {
      result[date].negative += parseFloat(value || 0);
    }

    if (result[date].positive + result[date].negative !== 0) {
      result[date].value =
        result[date].positive / (result[date].positive + result[date].negative);
    }
  });

  const resultArray = Object.values(result).map(
    ({ date, datapoint, positive, negative, value }) => ({
      date,
      datapoint,
      positive,
      negative,
      value,
    })
  );

  return resultArray.sort((a, b) => new Date(a.date) - new Date(b.date));
};

const sortAndExtractData = (data) => {
  data.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Extract and return an array with date, datapoint, and value
  const result = data.map((entry) => {
    const { date, datapoint, value } = entry;
    return { date, datapoint, value };
  });

  return result;
};

const sortDataChronologically = (data) => {
  return data.sort((a, b) => new Date(a.date) - new Date(b.date));
};

const sortAndExtractDataWithAggregation = (data) => {
  data.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Initialize an object to store aggregated data
  const aggregatedData = {};

  // Iterate through the original data
  data.forEach((entry) => {
    const { date, datapoint, value, lga } = entry;

    // Create a unique key for aggregation
    const key = `${date}_${datapoint}`;

    // Initialize an array for each unique key if it doesn't exist
    if (!aggregatedData[key]) {
      aggregatedData[key] = {
        date,
        datapoint,
        lga: [],
        value: 0,
      };
    }

    // Push the lga value to the lga array
    if (!aggregatedData[key].lga.includes(lga)) {
      aggregatedData[key].lga.push(lga);
    }

    // Add the value to the aggregated value
    aggregatedData[key].value += Number(value);
  });

  // Convert the aggregated data object into an array
  const result = Object.values(aggregatedData);

  return result;
};

const createColourScale = (data, minColour, maxColour) => {
  // Extract values from your data
  const values = data.map((d) => parseInt(d.value));
  const max = Math.max(...values);

  // Define the color scale
  const colourScale = d3
    .scaleLinear()
    .domain([0, max]) // 0 to the maximum value in your dataset
    .range([minColour, maxColour]); // Colors to interpolate between

  return colourScale;
};

const sortAndExtractCasesDataWithAggregation = (data) => {
  data.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Initialize an object to store aggregated data
  const aggregatedData = {};

  // Iterate through the original data
  data.forEach((entry) => {
    const { date, datapoint, value, lga } = entry;

    // Check if the datapoint is 'Cases'
    if (datapoint === "Cases") {
      // Create a unique key for aggregation
      const key = `${date}_Cases`;

      // Initialize an array for each unique key if it doesn't exist
      if (!aggregatedData[key]) {
        aggregatedData[key] = {
          date,
          datapoint: "Cases", // Set datapoint to 'Cases'
          lga: [],
          value: 0,
        };
      }

      // Push the lga value to the lga array
      if (!aggregatedData[key].lga.includes(lga)) {
        aggregatedData[key].lga.push(lga);
      }

      // Add the value to the aggregated value
      aggregatedData[key].value += Number(value);
    }
  });

  // Convert the aggregated data object into an array
  const result = Object.values(aggregatedData);

  return result;
};

const getSegments = (data, datapoints, type) => {
  // Initialize an object to store the sums for each datapoint
  const groupedData = {};

  // Calculate the sum of values for each datapoint
  data.forEach((item) => {
    if (datapoints.includes(item[type])) {
      groupedData[item[type]] =
        (groupedData[item[type]] || 0) + parseInt(item.value);
    }
  });

  // Convert the grouped data into the desired result format
  const result = Object.entries(groupedData).map(([label, value]) => ({
    label,
    value,
  }));

  return result;
};

const formatNumberWithCommas = (number) => {
  const numberStr = number.toString();
  const formattedNumber = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return formattedNumber;
};

export {
  sumValuesByDataPoint,
  sumValuesByData,
  sumValuesByDiseaseAndDatapoint,
  formatNumber,
  getUniqueValues,
  processData,
  processMBCData,
  extractLabels,
  fetchData,
  fetchInitData,
  sumDeliveries,
  pieDeliveries,
  pieDeliveries2,
  sortYears,
  sortMonths,
  sortQuarters,
  sortAlphabetically,
  sortAgeRanges,
  filterDataset,
  calculatePositivityRatio,
  sortAndExtractData,
  sortDataChronologically,
  sortAndExtractDataWithAggregation,
  sortAndExtractCasesDataWithAggregation,
  createColourScale,
  getSegments,
  formatNumberWithCommas,
};
