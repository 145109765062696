import React, { useContext, useEffect, useState } from "react";
import * as d3 from "d3";
import * as topojson from "topojson-client"; 
import { DataContext } from "../contexts/DataContext.jsx";
import PieChart from "../charts/PieChart.jsx";
import MapChart from "../charts/MapChart.jsx";
import { getSegments, extractLabels, getUniqueValues } from "../functions.js";
import lgLGAs from "../data/lgLGAs.topojson"

const EpidemiologyChartBar2 = ({ colourScale }) => {
  const { state } = useContext(DataContext);
  const {
    filteredEpiData,
    filteredEpiDiseaseData
  } = state;

  const [mapData, setMapData] = useState(null)
  const disease = getUniqueValues(filteredEpiDiseaseData, 'disease')

  // Set LGA Data
  useEffect(() => {
    d3.json(lgLGAs).then(topojsonData => {
      const {LGAs} = topojsonData.objects;
      setMapData(topojson.feature(topojsonData, LGAs))
    })
  }, [])

  const slices = getUniqueValues(filteredEpiData, 'disease')

  const PChartMargin = 5;
  const PChartOffSetCentre = 0;

  const grey = "rgba(0,0,0,0.1)"

  // Pie Chart
  const PieChartTitle = "Data Distribution";
  const pieData = getSegments(filteredEpiData, slices, 'disease');
  const key = extractLabels(pieData);

  // Map Chart
  const mapChartTitle = ""
  const mapColour = "#74B72E"
  const mapHoveredColour = "#466D1D"

  return (
    <div className="epichartbar2">
      <PieChart
      title={PieChartTitle}
      data={pieData}
      colourScale={colourScale}
      colorLegend="color-legend-hidden"
      legendItem="legend-item-hidden"
      legendLabel="legend-label-hidden"
      keys={key}
      margin={PChartMargin}
      backgroundColour={grey}
      offset={PChartOffSetCentre}
      />
      <MapChart
        title={mapChartTitle}
        backgroundColour={grey}
        data={filteredEpiDiseaseData}
        mapData={mapData}
        mapColour={mapColour}
        mapHoveredColour={mapHoveredColour}
        sub='lga'
        minColour='white'
        maxColour="#960018"
        valueTitle={disease}
      />
    </div>
  );
};

export default EpidemiologyChartBar2;