import React, { useState, useEffect, useContext } from "react";
import { DataContext } from "../contexts/DataContext.jsx";
import MultiselectDropdown from "../components/MultiselectDropdown.jsx";
import { getUniqueValues, sortYears, sortMonths, sortAlphabetically, sortAgeRanges } from "../functions.js";
import HivData from "../data/HivData.jsx";

const HivSlicerBar = () => {
  const { dispatch, state } = useContext(DataContext);
  const { hivData } = state

  // Selection State
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedLGAs, setSelectedLGAs] = useState([]);
  const [selectedAges, setSelectedAges] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);

  // Display State
  const [displayYears, setDisplayYears] = useState("");
  const [displayMonths, setDisplayMonths] = useState("");
  const [displayLGAs, setDisplayLGAs] = useState("");
  const [displayAges, setDisplayAges] = useState("");
  const [displayGenders, setDisplayGenders] = useState("");

  // Slicers
  const [yearSelectFilter, setYearSelectFilter] = useState([]);
  const [monthSelectFilter, setMonthSelectFilter] = useState([]);
  const [LGASelectFilter, setLGASelectFilter] = useState([]);
  const [AgeSelectFilter, setAgeSelectFilter] = useState([]);
  const [genderSelectFilter, setGenderSelectFilter] = useState([]);

  // FILTERED DATA
  useEffect(() => {
    const newHivData = hivData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const LGAMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);
      const ageMatch =
        selectedAges.length === 0 ||
        selectedAges.includes(row.age);
      const genderMatch =
        selectedGenders.length === 0 || selectedGenders.includes(row.gender);

      return yearMatch && monthMatch && LGAMatch && ageMatch && genderMatch;
    });

    dispatch({
      type: "UPDATE_FILTERED_HIV_DATA",
      payload: newHivData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedLGAs,
    selectedAges,
    selectedGenders,
    hivData,
  ]);

  // MULTISELECT FILTERS
  // Year
  useEffect(() => {
    const yearFilteredData = hivData.filter((row) => {
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const lgaMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);
      const ageMatch =
        selectedAges.length === 0 ||
        selectedAges.includes(row.age);
      const genderMatch =
        selectedGenders.length === 0 || selectedGenders.includes(row.gender);

      return monthMatch && lgaMatch && ageMatch && genderMatch;
    });

    setYearSelectFilter(yearFilteredData);
  }, [selectedMonths, selectedLGAs, selectedAges, selectedGenders, hivData]);

  // Month
  useEffect(() => {
    const monthFilteredData = hivData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const lgaMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);
      const ageMatch =
        selectedAges.length === 0 ||
        selectedAges.includes(row.age);
      const genderMatch =
        selectedGenders.length === 0 || selectedGenders.includes(row.gender);

      return yearMatch && lgaMatch && ageMatch && genderMatch;
    });

    setMonthSelectFilter(monthFilteredData);
  }, [selectedYears, selectedLGAs, selectedAges, selectedGenders, hivData]);

  // LGA
  useEffect(() => {
    const lgaFilteredData = hivData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const ageMatch =
        selectedAges.length === 0 ||
        selectedAges.includes(row.age);
      const genderMatch =
        selectedGenders.length === 0 || selectedGenders.includes(row.gender);

      return yearMatch && monthMatch && ageMatch && genderMatch;
    });

    setLGASelectFilter(lgaFilteredData);
  }, [selectedYears, selectedMonths, selectedAges, selectedGenders, hivData, dispatch]);

  // Age
  useEffect(() => {
    const ageFilteredData = hivData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const lgaMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);
      const genderMatch =
        selectedGenders.length === 0 || selectedGenders.includes(row.gender);

      return yearMatch && monthMatch && lgaMatch && genderMatch;
    });

    setAgeSelectFilter(ageFilteredData);
  }, [selectedYears, selectedMonths, selectedLGAs, selectedGenders, hivData, dispatch]);

  // Gender
  useEffect(() => {
    const genderFilteredData = hivData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const lgaMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);
      const ageMatch =
        selectedAges.length === 0 ||
        selectedAges.includes(row.age);

      return yearMatch && monthMatch && lgaMatch && ageMatch;
    });

    setGenderSelectFilter(genderFilteredData);
  }, [selectedYears, selectedMonths, selectedLGAs, selectedAges, hivData, dispatch]);

  // Select Functionality
  const handleYearSelect = (selectedList) => {
    setSelectedYears(selectedList);
    setDisplayYears(`${selectedList.length} options selected`);
  };

  const handleMonthSelect = (selectedList) => {
    setSelectedMonths(selectedList);
    setDisplayMonths(`${selectedList.length} options selected`);
  };

  const handleLGASelect = (selectedList) => {
    setSelectedLGAs(selectedList);
    setDisplayLGAs(`${selectedList.length} options selected`);
  };

  const handleAgeSelect = (selectedList) => {
    setSelectedAges(selectedList);
    setDisplayAges(`${selectedList.length} options selected`);
  };

  const handleGenderSelect = (selectedList) => {
    setSelectedGenders(selectedList);
    setDisplayGenders(`${selectedList.length} options selected`);
  };

  const SelectedCountDisplay = ({ selectedOptions }) => {
    const selectedCount = selectedOptions.length;
    return <div>{`${selectedCount} options selected`}</div>;
  };

  return (
    <>
    <HivData />
    <div className="slicerbarHiv">
      <MultiselectDropdown
        label="Year"
        id="year"
        placeholder="Year"
        options={sortYears(getUniqueValues(yearSelectFilter, "year"))}
        selectedValues={selectedYears}
        onChange={handleYearSelect}
        multiselectClass='multiselect-dropdown'
      />
      <MultiselectDropdown
        label="Month"
        id="month"
        placeholder="Month"
        options={sortMonths(getUniqueValues(monthSelectFilter, "month"))}
        selectedValues={selectedMonths}
        onChange={handleMonthSelect}
        multiselectClass='multiselect-dropdown'
      />
      <MultiselectDropdown
        label="LGA"
        id="lga"
        placeholder="Local Government Area"
        options={sortAlphabetically(getUniqueValues(LGASelectFilter, "lga"))}
        selectedValues={selectedLGAs}
        onChange={handleLGASelect}
        multiselectClass='multiselect-dropdown'
      />
      <MultiselectDropdown
        label="Age"
        id="age"
        placeholder="Age Range"
        options={sortAgeRanges(getUniqueValues(AgeSelectFilter, "age"))}
        selectedValues={selectedAges}
        onChange={handleAgeSelect}
        multiselectClass='multiselect-dropdown'
      />
      <MultiselectDropdown
        label="Gender"
        id="gender"
        placeholder="Gender"
        options={getUniqueValues(genderSelectFilter, "gender")}
        selectedValues={selectedGenders}
        onChange={handleGenderSelect}
        multiselectClass='multiselect-dropdown'
      />
    </div>
    </>
  );
};

export default HivSlicerBar;
