import React from 'react'

const HeaderTitle = () => {
  return (
    <div className='headertitle'>
        <p>
            LAGOS STATE HEALTH SYSTEM DASHBOARD
        </p>
    </div>
  )
}

export default HeaderTitle