import React from 'react'
import TuberculosisSlicerBar from '../Tuberculosis/TuberculosisSlicerBar.jsx'
import TuberculosisTitleBar from '../Tuberculosis/TuberculosisTitleBar.jsx'
import TuberculosisCardBar from '../Tuberculosis/TuberculosisCardBar.jsx'
import TuberculosisChartBar from '../Tuberculosis/TuberculosisChartBar.jsx'
import TuberculosisChartBar2 from '../Tuberculosis/TuberculosisChartBar2.jsx'

const Tuberculosis = ({colourScale}) => {
  return (
    <div className='tuberculosisdashboard'>
        <TuberculosisSlicerBar />
        <TuberculosisTitleBar />
        <TuberculosisCardBar />
        <TuberculosisChartBar colourScale={colourScale}/>
        <TuberculosisChartBar2 colourScale={colourScale}/>
    </div>
  )
}

export default Tuberculosis