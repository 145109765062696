import React, { useState, useEffect, useRef } from "react";

const MultiselectDropdown = ({
  id,
  label,
  options,
  placeholder,
  onChange,
  selectedValues,
  maxDisplayedItems = 3, // Default value if not provided
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("mousedown", handleMouseDown);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    const updatedSelectedValues = () => {
      console.log("Changed")
    }

    onChange(updatedSelectedValues);
  };

  return (
    <div className="multiselect-dropdown" ref={dropdownRef}>
      <label htmlFor={id}>{label}</label>
      <div className="dropdown">
        <div className="selected-values" onClick={handleToggle}>
          <div className="selected-text">
            {placeholder}
          </div>
          <div className="dropdown-arrow">&#9662;</div>
        </div>
        {isOpen && (
          <div className="options">
            <ul>
              {options.map((option) => (
                <li key={option} onClick={() => handleOptionClick(option)}>
                  <input
                    type="checkbox"
                    readOnly
                  />
                  {option}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiselectDropdown;
