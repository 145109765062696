import React, { useContext } from 'react'
import MultiBarChart from '../charts/MultiBarChart'
import { DataContext } from '../contexts/DataContext'
import { processMBCData, getUniqueValues } from '../functions'


const TuberculosisChartBar = ({colourScale}) => {
  const { state } = useContext(DataContext);
  const { filteredTuberculosisDatapointData } = state

  const datapoint = getUniqueValues(filteredTuberculosisDatapointData, 'datapoint')

  // MultiBarChart Props
  const mbcTitle = `${datapoint} (Year on Year by LGA)`
  const mbcData = processMBCData(filteredTuberculosisDatapointData)
  const mbcMargin = { top: 20, right: 30, bottom: 30, left: 30 };
  const mbcPadding = { main: 0.2, group: 0 };

  const grey = 'rgba(0,0,0,0.1)'

  return (
    <div className='tuberculosischartbar'>
      <MultiBarChart 
        title={mbcTitle}
        data={mbcData}
        margin={mbcMargin}
        padding={mbcPadding}
        colourLegend="color-legend-topleft"
        legendItem="legend-item-left"
        legendLabel="legend-label"
        colourScale={colourScale}
        backgroundColour={grey}
      />
    </div>
  )
}

export default TuberculosisChartBar