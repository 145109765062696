import React, { useContext } from "react";
import { DataContext } from "../contexts/DataContext.jsx";
import PieChart from "../charts/PieChart.jsx";
import Card from "../charts/Card.jsx";
import {
  getUniqueValues,
  pieDeliveries,
  sumValuesByDataPoint,
  extractLabels,
} from "../functions.js";

const HospitalsChartBar2 = ({ colourScale }) => {
  const { state } = useContext(DataContext);
  const {
    filteredDeliveriesData,
    filteredMaternityData,
    filteredOperationsData,
    filteredRtaData,
  } = state;

  // PieChart Specific Logic
  // Injury Cause Distribution
  const rtaDataPoints = getUniqueValues(filteredRtaData, 'datapoint')
  const icdPieData = pieDeliveries(filteredRtaData, rtaDataPoints);
  const icdKey = extractLabels(icdPieData);
  const PChartTitleICD = "Injury Cause Distribution";

  // Surgeries Distribution
  const operationsDataPoints = getUniqueValues(filteredOperationsData, 'datapoint')
  const sdPieData = pieDeliveries(filteredOperationsData, operationsDataPoints);
  const sdKey = extractLabels(sdPieData);
  const PChartTitleSD = "Surgeries Distribution";

  // Maternity Death
  const maternityDataPoints = ["Maternal Death", "Neo Natal Death", "Abortion"];
  const mPieData = pieDeliveries(filteredMaternityData, maternityDataPoints);
  const mKey = extractLabels(mPieData);
  const PChartTitleMD = "Maternal Death and Neo Natal Death";

  const PChartMargin = 5;
  const PChartOffSetLeft = 100;
  const PChartOffSetCentre = 0;

  // Card Logic
  const normalDeliveriesString = "Normal Deliveries";
  const forcepsDeliveriesString = "Forceps Deliveries";
  const breechDeliveriesString = "Breech Deliveries";
  const vacuumExtractionString = "Vacuum Extraction";
  const cSectionString = "C/Section";
  const ancString = "ANC";
  const pncString = "PNC";

  const normalDeliveries = sumValuesByDataPoint(
    filteredDeliveriesData,
    normalDeliveriesString
  );
  const forcepsDeliveries = sumValuesByDataPoint(
    filteredDeliveriesData,
    forcepsDeliveriesString
  );
  const breechDeliveries = sumValuesByDataPoint(
    filteredDeliveriesData,
    breechDeliveriesString
  );

  const vacuumExtraction = sumValuesByDataPoint(
    filteredDeliveriesData,
    vacuumExtractionString
  );

  const cSection = sumValuesByDataPoint(filteredDeliveriesData, cSectionString);

  const anc = sumValuesByDataPoint(filteredDeliveriesData, ancString);

  const vaginalDeliveries =
    normalDeliveries + forcepsDeliveries + breechDeliveries + vacuumExtraction;
  const deliveries = vaginalDeliveries + cSection;

  const deliveriesAnc = deliveries / anc;
  
  let deliveriesAncRatio = "0.00";

  if (!isNaN(deliveriesAnc) && deliveriesAnc >= 0) {
    deliveriesAncRatio = deliveriesAnc.toFixed(2);
  }

  const hospitalColour = "#0d69bf27";
  const maternityColour = "#0dbf4b27";

  return (
    <div className="chartbar2">
      <PieChart
        title={PChartTitleICD}
        data={icdPieData}
        colourScale={colourScale}
        colourLegend="color-legend-hidden"
        legendItem="legend-item-hidden"
        legendLabel="legend-label-hidden"
        keys={icdKey}
        margin={PChartMargin}
        backgroundColour={hospitalColour}
        offset={PChartOffSetCentre}
      />
      <PieChart
        title={PChartTitleSD}
        data={sdPieData}
        colourScale={colourScale}
        colourLegend="color-legend-hidden"
        legendItem="legend-item-hidden"
        legendLabel="legend-label-hidden"
        keys={sdKey}
        margin={PChartMargin}
        backgroundColour={hospitalColour}
        offset={PChartOffSetCentre}
      />
      <Card
        subtitle="Deliveries/Antenatal Ratio"
        value={deliveriesAncRatio}
        backgroundColour={maternityColour}
      />
      <PieChart
        title={PChartTitleMD}
        data={mPieData}
        colourScale={colourScale}
        colourLegend="color-legend-right"
        legendItem="legend-item-right"
        legendLabel="legend-label"
        keys={mKey}
        margin={PChartMargin}
        offset={PChartOffSetLeft}
        backgroundColour={maternityColour}
      />
    </div>
  );
};

export default HospitalsChartBar2;
