import React from 'react'
import CardTitle from '../components/CardTitle'

const TuberculosisTitleBar = () => {
  const title1 = "FIRST GROUPING"
  const title2 = "SECOND GROUPING"
    return (
        <div className="tuberculosistitlebar">
          <CardTitle cardTitle={title1}/>
          <CardTitle cardTitle={title2}/>
        </div>
      );
}

export default TuberculosisTitleBar