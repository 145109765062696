import React, { useState, useEffect, useContext } from "react";
import { DataContext } from "../contexts/DataContext.jsx";
import MultiselectDropdown from "../components/MultiselectDropdown.jsx";
import { getUniqueValues, sortYears, sortMonths, sortAlphabetically } from "../functions.js";
import HospitalsData from "../data/HospitalsData.jsx"

const HospitalsSlicerBar = () => {
  const { dispatch, state } = useContext(DataContext);
  const {slicers, attendanceData, deliveriesData, maternityData, operationsData, rtaData} = state

  // Selection State
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);

  // Display State
  const [displayYears, setDisplayYears] = useState("");
  const [displayMonths, setDisplayMonths] = useState("");
  const [displayHospitals, setDisplayHospitals] = useState("");
  const [displayGenders, setDisplayGenders] = useState("");

  // Slicers
  const [yearSelectFilter, setYearSelectFilter] = useState([]);
  const [monthSelectFilter, setMonthSelectFilter] = useState([]);
  const [hospitalSelectFilter, setHospitalSelectFilter] = useState([]);
  const [genderSelectFilter, setGenderSelectFilter] = useState([]);

  // FILTERED DATA
  // Attendance;
  useEffect(() => {
    const newAttendanceData = attendanceData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const hospitalMatch =
        selectedHospitals.length === 0 ||
        selectedHospitals.includes(row.hospital);
      const genderMatch =
        selectedGenders.length === 0 || selectedGenders.includes(row.gender);

      return yearMatch && monthMatch && hospitalMatch && genderMatch;
    });

    dispatch({
      type: "UPDATE_FILTERED_ATTENDANCE_DATA",
      payload: newAttendanceData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedHospitals,
    selectedGenders,
    attendanceData,
  ]);

  // Deliveries
  useEffect(() => {
    const newDeliveriesData = deliveriesData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const hospitalMatch =
        selectedHospitals.length === 0 ||
        selectedHospitals.includes(row.hospital);
      const genderMatch =
        selectedGenders.length === 0 || selectedGenders.includes(row.gender);

      return yearMatch && monthMatch && hospitalMatch && genderMatch;
    });

    dispatch({
      type: "UPDATE_FILTERED_DELIVERIES_DATA",
      payload: newDeliveriesData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedHospitals,
    selectedGenders,
    deliveriesData,
  ]);

  // Maternity
  useEffect(() => {
    const newMaternityData = maternityData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const hospitalMatch =
        selectedHospitals.length === 0 ||
        selectedHospitals.includes(row.hospital);
      const genderMatch =
        selectedGenders.length === 0 || selectedGenders.includes(row.gender);

      return yearMatch && monthMatch && hospitalMatch && genderMatch;
    });

    dispatch({
      type: "UPDATE_FILTERED_MATERNITY_DATA",
      payload: newMaternityData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedHospitals,
    selectedGenders,
    maternityData,
  ]);

  // Operations
  useEffect(() => {
    const newOperationsData = operationsData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const hospitalMatch =
        selectedHospitals.length === 0 ||
        selectedHospitals.includes(row.hospital);
      const genderMatch =
        selectedGenders.length === 0 || selectedGenders.includes(row.gender);

      return yearMatch && monthMatch && hospitalMatch && genderMatch;
    });

    dispatch({
      type: "UPDATE_FILTERED_OPERATIONS_DATA",
      payload: newOperationsData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedHospitals,
    selectedGenders,
    operationsData,
  ]);

  // Rta
  useEffect(() => {
    const newRtaData = rtaData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const hospitalMatch =
        selectedHospitals.length === 0 ||
        selectedHospitals.includes(row.hospital);
      const genderMatch =
        selectedGenders.length === 0 || selectedGenders.includes(row.gender);

      return yearMatch && monthMatch && hospitalMatch && genderMatch;
    });

    dispatch({
      type: "UPDATE_FILTERED_RTA_DATA",
      payload: newRtaData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedHospitals,
    selectedGenders,
    rtaData,
  ]);

  // MULTISELECT FILTERS
  // Year
  useEffect(() => {
    const yearFilteredData = slicers.filter((row) => {
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const hospitalMatch =
        selectedHospitals.length === 0 ||
        selectedHospitals.includes(row.hospital);
      const genderMatch =
        selectedGenders.length === 0 || selectedGenders.includes(row.gender);

      return monthMatch && hospitalMatch && genderMatch;
    });

    setYearSelectFilter(yearFilteredData);
  }, [selectedMonths, selectedHospitals, selectedGenders, slicers]);

  // Month
  useEffect(() => {
    const monthFilteredData = slicers.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const hospitalMatch =
        selectedHospitals.length === 0 ||
        selectedHospitals.includes(row.hospital);
      const genderMatch =
        selectedGenders.length === 0 || selectedGenders.includes(row.gender);

      return yearMatch && hospitalMatch && genderMatch;
    });

    setMonthSelectFilter(monthFilteredData);
  }, [selectedYears, selectedHospitals, selectedGenders, slicers]);

  // Hospital
  useEffect(() => {
    const hospitalFilteredData = slicers.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const genderMatch =
        selectedGenders.length === 0 || selectedGenders.includes(row.gender);

      return yearMatch && monthMatch && genderMatch;
    });

    setHospitalSelectFilter(hospitalFilteredData);
  }, [selectedYears, selectedMonths, selectedGenders, slicers, dispatch]);

  // Gender
  useEffect(() => {
    const genderFilteredData = slicers.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const hospitalMatch =
        selectedHospitals.length === 0 ||
        selectedHospitals.includes(row.hospital);

      return yearMatch && monthMatch && hospitalMatch;
    });

    setGenderSelectFilter(genderFilteredData);
  }, [selectedYears, selectedMonths, selectedHospitals, slicers, dispatch]);

  // Select Functionality
  const handleYearSelect = (selectedList) => {
    setSelectedYears(selectedList);
    setDisplayYears(`${selectedList.length} options selected`);
  };

  const handleMonthSelect = (selectedList) => {
    setSelectedMonths(selectedList);
    setDisplayMonths(`${selectedList.length} options selected`);
  };

  const handleHospitalSelect = (selectedList) => {
    setSelectedHospitals(selectedList);
    setDisplayHospitals(`${selectedList.length} options selected`);
  };

  const handleGenderSelect = (selectedList) => {
    setSelectedGenders(selectedList);
    setDisplayGenders(`${selectedList.length} options selected`);
  };

  const SelectedCountDisplay = ({ selectedOptions }) => {
    const selectedCount = selectedOptions.length;
    return <div>{`${selectedCount} options selected`}</div>;
  };

  return (
    <>
    <HospitalsData />
    <div className="slicerbarHospital">
      <MultiselectDropdown
        label="Year"
        id="year"
        placeholder="Year"
        options={sortYears(getUniqueValues(yearSelectFilter, "year"))}
        selectedValues={selectedYears}
        onChange={handleYearSelect}
        multiselectClass='multiselect-dropdown'
      />
      <MultiselectDropdown
        label="Month"
        id="month"
        placeholder="Month"
        options={sortMonths(getUniqueValues(monthSelectFilter, "month"))}
        selectedValues={selectedMonths}
        onChange={handleMonthSelect}
        multiselectClass='multiselect-dropdown'
      />
      <MultiselectDropdown
        label="Health Facility"
        id="hospital"
        placeholder="Health Facility"
        options={sortAlphabetically(getUniqueValues(hospitalSelectFilter, "hospital"))}
        selectedValues={selectedHospitals}
        onChange={handleHospitalSelect}
        multiselectClass='multiselect-dropdown'
      />
      <MultiselectDropdown
        label="Gender"
        id="gender"
        placeholder="Gender"
        options={getUniqueValues(genderSelectFilter, "gender")}
        selectedValues={selectedGenders}
        onChange={handleGenderSelect}
        multiselectClass='multiselect-dropdown'
      />
    </div>
    </>
  );
};

export default HospitalsSlicerBar;
