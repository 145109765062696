import React from 'react'
import EpidemiologySlicerBar from '../Epidemiology/EpidemiologySlicerBar.jsx'
import EpidemiologyTitleBar from '../Epidemiology/EpidemiologyTitleBar.jsx'
import EpidemiologyCardBar from '../Epidemiology/EpidemiologyCardBar.jsx'
import EpidemiologyChartBar from '../Epidemiology/EpidemiologyChartBar.jsx'
import EpidemiologyChartBar2 from '../Epidemiology/EpidemiologyChartBar2.jsx'

const Epidemiology = ({colourScale}) => {

  return (
    <div className='epidemiologydashboard'>
      <EpidemiologySlicerBar />
      <EpidemiologyTitleBar />
      <EpidemiologyCardBar />
      <EpidemiologyChartBar colourScale={colourScale}/>
      <EpidemiologyChartBar2 colourScale={colourScale}/>
    </div>
  )
}

export default Epidemiology