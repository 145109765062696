import React, { useEffect, useRef } from 'react'
import * as d3 from "d3";
import ChartHeader from "../components/ChartHeader.jsx";
import { createColourScale, getSegments, getUniqueValues, formatNumberWithCommas } from '../functions.js';

const MapChart = ({
    backgroundColour,
    title,
    data,
    mapData,
    mapColour,
    mapHoveredColour,
    minColour,
    maxColour,
    sub,
    valueTitle = ['Value'],
}) => {
  const svgRef = useRef(null);
  const parentRef = useRef(null);

  const handleResize = () => {
    if (data && svgRef.current && mapData) {
      const parentElement = parentRef.current;
      const containerWidth = parentElement.clientWidth;
      const containerHeight = parentElement.clientHeight;

      const datapoints = getUniqueValues(data, sub)
      const segmentData = getSegments(data, datapoints, sub)
      const colourScale = createColourScale(segmentData, minColour, maxColour)

      // Create the chart dimensions and margins
      const width = containerWidth;
      const height = containerHeight;

      // SVG Container
      const svg = d3
        .select(svgRef.current)
        .attr("class", "map-container")
        .attr("width", width)
        .attr("height", height)
  
      svg.selectAll("*").remove();

      // Projection and Path Generation
      const projection = d3.geoMercator()
      .fitSize([width, height], mapData)
      const path = d3.geoPath(projection);

      // Draw your map features
      svg
        .selectAll('path')
        .data(mapData.features)
        .enter()
        .append('path')
        .attr('class', 'states')
        .attr('d', path)
        .attr('fill', (feature) => {
          const matchingData = segmentData.find((d) => d.label === feature.properties.name);
          return matchingData ? colourScale(matchingData.value) : mapColour;
        })
        .attr('stroke', 'black')
        .attr('stroke-width', '.75')
        .on('mouseover', (event, feature) => {
          d3.select(event.target).attr('fill', mapHoveredColour);

          // Find the corresponding data value
          const matchingData = segmentData.find((d) => d.label === feature.properties.name);
          
          // Create a group for the label and value text and rectangle
          const tooltip = svg.append("g").attr("class", "tooltip");

          // X and Y Positions
          const [x, y] = d3.pointer(event);

          // Calculate the width and height based on text length
          const labelWidth = feature.properties.name.length * 8; 
          const widthPadding = 30; 
          const fixedNumber = 100; // Your fixed number
          const calculatedValue = labelWidth + widthPadding;
          const valueTitleLength = (valueTitle[0].length * 7.5);

          const rectWidth = Math.max(fixedNumber, calculatedValue, valueTitleLength);
          const rectHeight = 60;

          // Create a rectangle background
          tooltip
          .append("rect")
          .attr("class", "tooltip-box")
          .attr("x", x - rectWidth / 2 ) 
          .attr("y", y - rectHeight / 2 ) 
          .attr("width", rectWidth)
          .attr("height", rectHeight)
          .attr("rx", 2.5)
          .attr("ry", 2.5)
          .attr("fill", "rgba(255, 255, 255, 0.9)");

          // Display the label text as justified
          tooltip
          .append("text")
          .attr("class", "percentage-label")
          .attr("x", x - rectWidth / 2 + 10) 
          .attr("y", y - rectHeight / 2 + 20) 
          .style("text-anchor", "start") 
          .style("font-family", "Arial")
          .style("font-weight", "bold")
          .style("font-size", "14px") 
          .text(feature.properties.name);
          
          if (matchingData) {
            const value = matchingData.value;

          // Display the value text as justified
          tooltip
          .append("text")
          .attr("class", "percentage-value")
          .attr("x", x - rectWidth / 2 + 10) 
          .attr("y", y + 5) 
          .style("text-anchor", "start") 
          .style("font-family", "Arial")
          .style("font-weight", "normal")
          .style("font-size", "11px") 
          .text(`${valueTitle}: ${formatNumberWithCommas(value)}`);
          }


        })
        .on('mouseout', (event) => {
          const matchingData = segmentData.find(
            (d) => d.label === event.target.__data__.properties.name
          );
          d3.select(event.target).attr(
            'fill',
            matchingData ? colourScale(matchingData.value) : mapColour
          );
          svg.selectAll('.tooltip').remove();
        })
      }
  }


  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [data, mapData]);
  
  return (
    <div
      className="mapchart"
      style={{
        backgroundColor: `${backgroundColour}`,
      }}
    >
      <ChartHeader title={title} />
      <svg
        ref={parentRef}
        className="chartsvg"
        style={{
          height: "90%",
          width: "90%",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <g ref={svgRef}
        />
      </svg>
    </div>
  )
}

export default MapChart