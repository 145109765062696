import React, {useContext} from 'react';
import Card from '../charts/Card';
import { DataContext } from '../contexts/DataContext';
import { sumValuesByDiseaseAndDatapoint, sumValuesByDataPoint, formatNumber } from '../functions.js';


const EpidemiologyCardBar = () => {
  const { state } = useContext(DataContext);
  const { filteredEpiData, filteredEpiDiseaseData } = state

  // DISEASES (CASES)
  // Card Data
  const afpUnits = sumValuesByDiseaseAndDatapoint(filteredEpiData, 'AFP', 'Cases');
  const diphtheriaUnits = sumValuesByDiseaseAndDatapoint(filteredEpiData, 'Diphtheria', 'Cases');
  const lassaUnits = sumValuesByDiseaseAndDatapoint(filteredEpiData, 'Lassa Fever', 'Cases');
  const measlesUnits = sumValuesByDiseaseAndDatapoint(filteredEpiData, 'Measles', 'Cases');
  const monkeyUnits = sumValuesByDiseaseAndDatapoint(filteredEpiData, 'Monkey Pox', 'Cases');

  // Card Formatted
  const afpRound = formatNumber(afpUnits);
  const diphtheriaRound = formatNumber(diphtheriaUnits);
  const lassaRound = formatNumber(lassaUnits);
  const measlesRound = formatNumber(measlesUnits);
  const monkeyRound = formatNumber(monkeyUnits);

  // DISAGGREGATED
  const cases = sumValuesByDataPoint(filteredEpiDiseaseData, 'Cases')
  const confirmed = sumValuesByDataPoint(filteredEpiDiseaseData, 'Confirmed')
  const deaths = sumValuesByDataPoint(filteredEpiDiseaseData, 'Deaths')

  const filler = 0
  const grey = "rgba(0,0,0,0.1)"

  return (
    <div className="hivcardbar">
      <Card
        subtitle="AFP"
        value={afpRound}
        backgroundColour={grey}
      />
      <Card
        subtitle="Diphtheria"
        value={diphtheriaRound}
        backgroundColour={grey}
      />
      <Card
        subtitle="Lassa Fever"
        value={lassaRound}
        backgroundColour={grey}
      />
      <Card
        subtitle="Measles"
        value={measlesRound}
        backgroundColour={grey}
      />
      <Card
        subtitle="Monkey Pox"
        value={monkeyRound}
        backgroundColour={grey}
      />
      <Card
        subtitle="Cases"
        value={cases}
        backgroundColour={grey}
      />
      <Card
        subtitle="Confirmed"
        value={confirmed}
        backgroundColour={grey}
      />
      <Card
        subtitle="Deaths"
        value={deaths}
        backgroundColour={grey}
      />
    </div>
  )
}

export default EpidemiologyCardBar