import React from 'react'
import HospitalsSlicerBar from '../Hospitals/HospitalsSlicerBar'
import HospitalsCardBar from '../Hospitals/HospitalsCardBar'
import HospitalsChartBar from '../Hospitals/HospitalsChartBar'
import HospitalsChartBar2 from '../Hospitals/HospitalsChartBar2'

const Hospitals = ({colourScale}) => {
  return (
    <div className='hospitalsdashboard'>
      <HospitalsSlicerBar />
      <HospitalsCardBar />
      <HospitalsChartBar colourScale={colourScale}/>
      <HospitalsChartBar2 colourScale={colourScale}/>
    </div>
  )
}

export default Hospitals
