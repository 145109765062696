import React from 'react'
import CardTitle from '../components/CardTitle'

const HivTitleBar = () => {
    const title1 = "FIRST GROUPING"
    const title2 = "SECOND GROUPING"
      return (
          <div className="hivtitlebar">
            <CardTitle cardTitle={title1}/>
            <CardTitle cardTitle={title2}/>
          </div>
        );
}

export default HivTitleBar