import React from 'react';
import { useLocation } from 'react-router-dom';

const MenuBar = () => {
  const location = useLocation();

  // Define a function to check if a menu item is active based on its href
  const isActive = (href) => {
    return location.pathname === href;
  };

  return (
    <div className='menubar'>
      <a href='/' className={isActive('/') ? 'active' : ''}>Home</a>
      <a href='/lasambus' className={isActive('/lasambus') ? 'active' : ''}>Lasambus</a>
      <a href='/programs/epi' className={isActive('/programs/epi') ? 'active' : ''}>Epidemiology</a>
      <a href='/programs/hiv' className={isActive('/programs/hiv') ? 'active' : ''}>HIV</a>
      <a href='/programs/tuberculosis' className={isActive('/programs/tuberculosis') ? 'active' : ''}>Tuberculosis</a>
      {/* <a href='/programs' className={isActive('/programs') ? 'active' : ''}>Programs</a> */}
    </div>
  );
};

export default MenuBar;