import React from "react";
import DropdownMenu from "../components/DropdownMenuNull.jsx";

const LasambusSlicerBar = () => {

  const years = ["2021", "2022", "2023"]
  const months = [
    "Jan", 
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ]
  const ageGroups = [
    '0-1',
    '2-5',
    '6-9',
    '10-14',
    '15-18',
    '19-40',
    '41-60',
    '61-75',
    '76-120',
  ]
  const healthFacility = [
    "GH, Agbowa",
    "GH, Ajeromi",
    "GH, Lagos",
    "LASUTH"
  ]

  const handleSelect = () => {
    console.log('Selecting')
  }
  return (
    <div className="slicerbarLasambus">
      <DropdownMenu
        label="Year"
        id="year"
        placeholder="Year"
        options={years}
        // selectedValues={selectedYears}
        onChange={handleSelect}
      />
      <DropdownMenu
        label="Month"
        id="month"
        placeholder="Month"
        options={months}
        // selectedValues={selectedMonths}
        onChange={handleSelect}
      />
      <DropdownMenu
        label="Age Group"
        id="agegroup"
        placeholder="Age Group"
        options={ageGroups}
        // selectedValues={selectedHospitals}
        onChange={handleSelect}
      />
      <DropdownMenu
        label="Health Facility"
        id="healthfacilty"
        placeholder="Health Facility"
        options={healthFacility}
        // selectedValues={selectedGenders}
        onChange={handleSelect}
      />
    </div>
  );
};

export default LasambusSlicerBar;
