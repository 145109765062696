import React from 'react'

const Logo = () => {
  return (
    <div className="headerlogo">
        <div className="icon-container">
        <span className="eicon-logo" />
        </div>
    </div>
  )
}

export default Logo