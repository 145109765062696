import React, { useContext } from "react";
import { DataContext } from "../contexts/DataContext.jsx";
import MultiBarChart from "../charts/MultiBarChart.jsx";
import PieChart from "../charts/PieChart.jsx";
import { processData, extractLabels, sumDeliveries } from "../functions.js";

const HospitalsChartBar = ({ colourScale }) => {
  const { state } = useContext(DataContext);
  const { filteredAttendanceData, filteredDeliveriesData } = state;

  // MultiChartBar Specific Logic
  const chartData = processData(filteredAttendanceData);
  const MbCharttitle = "Comparative Analysis (Year on Year Patient Attendance)";
  const MbChartMargin = { top: 20, right: 30, bottom: 50, left: 30 };
  const MbChartPadding = { main: 0.2, group: 0 };

  // PieChart Specific Logic
  const deliveryTypes = [
    "Normal Deliveries",
    "Forceps Deliveries",
    "Breech Deliveries",
    "Vacuum Extraction",
    "C/Section",
  ];

  const pieData = sumDeliveries(filteredDeliveriesData, deliveryTypes);
  const key = extractLabels(pieData);
  const PChartTitle = "C/Section and Vaginal Deliveries";
  const PChartMargin = 5;
  const PChartOffSetLeft = 100;

  const hospitalColour = "#0d69bf27";
  const maternityColour = "#0dbf4b27";

  return (
    <div className="chartbar">
      <MultiBarChart
        title={MbCharttitle}
        data={chartData}
        margin={MbChartMargin}
        padding={MbChartPadding}
        colourScale={colourScale}
        colourLegend="color-legend-topleft"
        legendItem="legend-item-left"
        legendLabel="legend-label"
        backgroundColour={hospitalColour}
      />
      <PieChart
        title={PChartTitle}
        data={pieData}
        colourScale={colourScale}
        keys={key}
        colourLegend="color-legend-right"
        legendItem="legend-item-right"
        legendLabel="legend-label"
        margin={PChartMargin}
        offset={PChartOffSetLeft}
        backgroundColour={maternityColour}
      />
    </div>
  );
};

export default HospitalsChartBar;
