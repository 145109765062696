import React, { useContext } from 'react';
import { DataContext } from '../contexts/DataContext.jsx';
import MultiBarChart from '../charts/MultiBarChart.jsx';
import LineChart from '../charts/LineChart.jsx';
import { processMBCData, calculatePositivityRatio } from '../functions.js';


const HivChartBar = ({colourScale}) => {
  const { state } = useContext(DataContext);
  const { filteredHivData } = state;

  // MultiBarChart Props
  const mbcTitle = "Positive Cases (Year on Year by LGA)"
  const mbcData = processMBCData(filteredHivData)
  const mbcMargin = { top: 20, right: 30, bottom: 30, left: 30 };
  const mbcPadding = { main: 0.2, group: 0 };
  
  // LineChart Props
  const lcTitle = "Positive Cases Trend"
  const lcData = calculatePositivityRatio(filteredHivData)
  const lcPadding = {main: 0.2}
  const positivityKeys = []

  const grey = 'rgba(0,0,0,0.1)'

  return (
    <div className='hivchartbar'>
      <MultiBarChart 
        title={mbcTitle}
        data={mbcData}
        margin={mbcMargin}
        padding={mbcPadding}
        colourLegend="color-legend-topleft"
        legendItem="legend-item-left"
        legendLabel="legend-label"
        colourScale={colourScale}
        backgroundColour={grey}
      />
      <LineChart 
        title={lcTitle}
        data={lcData}
        padding={lcPadding}
        colorLegend="color-legend-topleft"
        legendItem="legend-item-left"
        legendLabel="legend-label"
        keys={positivityKeys}
        colourScale={colourScale}
        backgroundColour={grey}
      />
    </div>
  )
}

export default HivChartBar