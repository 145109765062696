import React from "react";

const ProgramItem = ({ route, item, backgroundColor }) => {
  return (
      <a 
        href={route} 
        className="programitem"
        style={{
            backgroundColor: `${backgroundColor}`,
          }}
      >
        {item}
      </a>
  );
};

export default ProgramItem;