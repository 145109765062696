import React from 'react'

const CardTitle = ({ cardTitle }) => {
    return (
      <div className="cardtitle">
        <p>{cardTitle}</p>
      </div>
    );
  };

export default CardTitle