import React, { useContext } from "react";
import Card from "../charts/Card.jsx";
import { DataContext } from "../contexts/DataContext.jsx";
import { sumValuesByDataPoint, formatNumber } from "../functions.js";

const HospitalsCardBar = () => {
  const { state } = useContext(DataContext);
  const {
    filteredAttendanceData,
    filteredDeliveriesData,
    filteredMaternityData,
  } = state;

  // Data Functions
  const totalAttendance = "Total Attendances";
  const outPatientDeath = "Out-Patient Death";
  const broughtInDead = "Brought in Dead";
  const inPatientDeath = "In-Patient Death";
  const admissions = "Admissions";
  const discharges = "Discharges";
  const liveBirth = "Live Birth";
  const anc = "ANC";
  const pnc = "PNC";
  const maternalDeath = "Maternal Death";

  // Card Calculations
  const patientVisitsUnits = sumValuesByDataPoint(
    filteredAttendanceData,
    totalAttendance
  );
  const admissionsUnits = sumValuesByDataPoint(
    filteredAttendanceData,
    admissions
  );
  const dischargesUnits = sumValuesByDataPoint(
    filteredAttendanceData,
    discharges
  );
  const outPatientDeathUnits = sumValuesByDataPoint(
    filteredAttendanceData,
    outPatientDeath
  );
  const inPatientDeathUnits = sumValuesByDataPoint(
    filteredAttendanceData,
    inPatientDeath
  );
  const broughtInDeadUnits = sumValuesByDataPoint(
    filteredAttendanceData,
    broughtInDead
  );
  const totalDeathUnits =
    outPatientDeathUnits + inPatientDeathUnits + broughtInDeadUnits;
  const liveBirthUnits = sumValuesByDataPoint(
    filteredAttendanceData,
    liveBirth
  );
  const ancUnits = sumValuesByDataPoint(filteredDeliveriesData, anc);
  const pncUnits = sumValuesByDataPoint(filteredDeliveriesData, pnc);
  const maternalDeathUnits = sumValuesByDataPoint(
    filteredMaternityData,
    maternalDeath
  );

  // Card Calculations Round Up
  const patientVisitsRound = formatNumber(patientVisitsUnits);
  const admissionsRound = formatNumber(admissionsUnits);
  const dischargesRound = formatNumber(dischargesUnits);
  const totalDeathRound = formatNumber(totalDeathUnits);
  const ancRound = formatNumber(ancUnits);
  const liveBirthRound = formatNumber(liveBirthUnits);
  const pncRound = formatNumber(pncUnits);
  const maternalDeathRound = formatNumber(maternalDeathUnits);

  const hospitalColour = "#0d69bf27";
  const maternityColour = "#0dbf4b27";

  return (
    <div className="cardbar">
      <Card
        subtitle="Patients Visits"
        value={patientVisitsRound}
        backgroundColour={hospitalColour}
      />
      <Card
        subtitle="Admissions"
        value={admissionsRound}
        backgroundColour={hospitalColour}
      />
      <Card
        subtitle="Discharges"
        value={dischargesRound}
        backgroundColour={hospitalColour}
      />
      <Card
        subtitle="Death"
        value={totalDeathRound}
        backgroundColour={hospitalColour}
      />
      <Card
        subtitle="Antenatal Care"
        value={ancRound}
        backgroundColour={maternityColour}
      />
      <Card
        subtitle="Live Birth"
        value={liveBirthRound}
        backgroundColour={maternityColour}
      />
      <Card
        subtitle="Post Natal Care"
        value={pncRound}
        backgroundColour={maternityColour}
      />
      <Card
        subtitle="Maternal Death"
        value={maternalDeathRound}
        backgroundColour={maternityColour}
      />
    </div>
  );
};

export default HospitalsCardBar;
