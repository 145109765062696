import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext.jsx";
import LoginModal from "../modals/LoginModal.jsx";
import LogoutButton from "../components/LogoutButton.jsx";
import Logo from "../components/Logo.jsx";
import HeaderTitle from "../components/HeaderTitle.jsx";
import MenuBar from "../components/MenuBar.jsx";
import LoadingIcon from "../components/LoadingIcon.jsx";
import { useLoading } from "../contexts/LoadingContext.jsx";

const Header = () => {
  const { loggedIn, isLoggedIn, setLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const { isLoading } = useLoading();

  let logoutTimeout;

  const handleLogout = () => {
    clearTimeout(logoutTimeout);
    logoutTimeout = setTimeout(logoutUser, 5 * 60 * 1000);
  };

  const handleLogoutButton = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setLoggedIn(false);
    setIsLoggedIn({
      firstname: "",
      lastname: "",
      username: "",
      initials: "",
    });
  };

  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setLoggedIn(false);
    setIsLoggedIn({
      firstname: "",
      lastname: "",
      username: "",
      initials: "",
    });
  }
  
  document.addEventListener("mousemove", handleLogout);
  document.addEventListener("keypress", handleLogout);

  return (
    <div className="header">
      <div className="topheader">
        <Logo />
        <HeaderTitle />
          {loggedIn && <LogoutButton handleLogout={handleLogoutButton} />}
      </div>
      <MenuBar />
      {!loggedIn && <LoginModal />}
      {isLoading && <LoadingIcon 
        strokeColour='#127BDE'
        strokeWidth='20'
      />}
    </div>
  );
};

export default Header;
