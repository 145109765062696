import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import ColourLegend from "../components/ColourLegend.jsx"
import ChartHeader from "../components/ChartHeader.jsx";

const MultiBarChart = ({
  title,
  data,
  colourScale,
  padding,
  colourLegend,
  legendItem,
  legendLabel,
  backgroundColour,
}) => {
  const svgRef = useRef(null);
  const parentRef = useRef(null);

  //Data
  const xArray = data[0];
  const xMBArray = data[1];
  const yValues = data[2];
  const yArray = data[2].flatMap((values) => values);

  useEffect(() => {
    const handleResize = () => {
      if (data && svgRef.current) {
        const parentElement = parentRef.current;
        const containerWidth = parentElement.clientWidth;
        const containerHeight = parentElement.clientHeight;
        const innerWidth = containerWidth * 0.9;
        const innerHeight = containerHeight * 0.6;

        const svg = d3
          .select(svgRef.current)
          .attr("class", "chart-container")
          .attr("width", innerWidth)
          .attr("height", innerHeight);
        // Group Scale
        const xScale = d3
          .scaleBand()
          .domain(xArray)
          .range([0, innerWidth])
          .padding(padding.main);

        // Subgroup Scale
        const xGroupScale = d3
          .scaleBand()
          .domain(xMBArray)
          .range([0, xScale.bandwidth()])
          .padding(padding.group);

        // Values Scale
        const yScale = d3
          .scaleLinear()
          .domain([0, d3.max(yArray)])
          .range([innerHeight, 0]);

        // xAxis
        const xAxis = d3.axisBottom(xScale);
        svg
          .select(".x-axis")
          .attr("transform", `translate(0, ${innerHeight})`)
          .call(xAxis)
          .selectAll("text")
          .attr("dy", "0.5em")
          .attr("text-anchor", "middle")
          .attr("fill", "black")
          .style("font-size", "1em")
          .style("transform", function(d) {
            // Rotate the text diagonally if there are more than 20 elements
            return shouldRotate(d) ? "rotate(-30deg)" : null;
          })
          .attr("text-anchor", function(d) {
            // Rotate the text diagonally if there are more than 20 elements
            return shouldRotate(d) ? "end" : null;
          })
          .each(function(d) {
            const textElement = d3.select(this);
            const textContent = textElement.text();
            const numElements = d3.selectAll(".x-axis .tick").size();

            // Logic for different text formatting based on the number of elements
            if (numElements <= 7) {
              // Case 1: 7 or fewer elements, show full text
              textElement.text(textContent);
            } else if (numElements <= 19) {
              // Case 2: Between 8 and 20 elements, show one word per line
              const words = textContent.split(" ");
              textElement.text(null); // Clear the current text
              const dyIncrement = 0.6; // Define the vertical spacing increment

              words.forEach((word, index) => {
                const dyValue = index * dyIncrement + dyIncrement / 2; // Calculate the dy value
                textElement
                  .append("tspan")
                  .attr("x", 0)
                  .attr("dy", `${dyValue}em`) // Use the calculated dy value
                  .text(word)
                  .attr("text-anchor", "middle");
              });
            } else {
              // Case 3: More than 20 elements, truncate text to 10 characters with three dots
              const truncatedText =
                textContent.length > 10
                  ? textContent.slice(0, 10) + "..."
                  : textContent;
              textElement.text(truncatedText);
            }
          });

        function shouldRotate(d) {
          // Function to determine if text should be rotated
          // based on the number of elements on the x-axis
          const numElements = d3.selectAll(".x-axis .tick").size();
          return numElements > 19;
        }

        // yAxis
        const yAxis = d3.axisLeft(yScale).ticks(5);
        svg
          .select(".y-axis")
          .call(yAxis)
          .selectAll(".tick line")
          .attr("x1", -5)
          .attr("stroke", "gray");

        // Generate yAxis tick values
        const yAxisTicks = yScale.ticks(5).map((tick) => ({
          value: tick,
          position: yScale(tick),
        }));

        // Guideline
        svg
          .selectAll(".guideline")
          .data(yAxisTicks)
          .join(
            (enter) => enter.append("line").attr("class", "guideline"),
            (update) => update,
            (exit) => exit.remove()
          )
          .attr("x1", 0)
          .attr("y1", (tickValue) => tickValue.position)
          .attr("x2", innerWidth)
          .attr("y2", (tickValue) => tickValue.position)
          .attr("stroke", "black")
          .attr("stroke-width", 0.5);
        // .attr("stroke-dasharray", "3 1");

        // Select all group elements with class "series"
        const series = svg.selectAll(".series").data(xArray);

        // Enter selection: create new group elements
        const seriesEnter = series
          .enter()
          .append("g")
          .attr("class", "series")
          .attr("transform", (d) => `translate(${xScale(d)}, 0)`)
          .on("mouseenter", function (event, d) {
            const [x, y] = d3.pointer(event);

            // const seriesData = yValues.find((item) => item[0] === d);
            const tooltip = svg.append("g").attr("class", "tooltip");

            const labelWidth = d.length * 8;
            const widthPadding = 20;
            const rectWidth = labelWidth + widthPadding;
            const rectHeight = 60;

            console.log(x, y, rectWidth)

            tooltip
              .append("rect")
              .attr("class", "tooltip-box")
              .attr("x", x)
              .attr("y", y - rectHeight / 2)
              .attr("width", rectWidth)
              .attr("height", rectHeight)
              .attr("rx", 2.5)
              .attr("ry", 2.5)
              .attr("fill", "rgba(255, 255, 255, 0.9)");

            tooltip
              .append("text")
              .attr("class", "series-title")
              .attr("x", x + 10)
              .attr("y", y - rectHeight / 2 + 20)
              .style("text-anchor", "start")
              .style("font-family", "Arial")
              .style("font-weight", "bold")
              .style("font-size", "14px")
              .text(`${d}`);

            // seriesData.forEach((item, index) => {
            //   tooltip
            //     .append("text")
            //     .attr("class", "data-value")
            //     .attr("x", x - rectWidth / 2 + 10)
            //     .attr("y", y - rectHeight / 2 + 40 + index * 15)
            //     .style("text-anchor", "start")
            //     .style("font-family", "Arial")
            //     .style("font-weight", "normal")
            //     .style("font-size", "11px")
            //     .text(`${item[0]}: ${item[1]}`);
            // });
          })
          .on("mouseleave", () => {
            svg.select(".tooltip").remove();
          });

        // Update selection: update existing group elements
        series.attr("transform", (d) => `translate(${xScale(d)}, 0)`);

        // Exit selection: remove any excess group elements
        series.exit().remove();

        // Select all bars within the group elements
        const bars = series
          .merge(seriesEnter)
          .selectAll("rect")
          .data((d, i) =>
            xMBArray.map((key, index) => ({
              key,
              value: yValues[index][i],
            }))
          );

        // Enter selection: create new bars
        bars
          .enter()
          .append("rect")
          .attr("x", (d) => xGroupScale(d.key))
          .attr("y", (d) => yScale(d.value))
          .attr("height", (d) => innerHeight - yScale(d.value))
          .attr("width", xGroupScale.bandwidth())
          .attr("fill", (d) => colourScale(d.key));

        // Update selection: update existing bars
        bars
          .attr("x", (d) => xGroupScale(d.key))
          .attr("width", xGroupScale.bandwidth())
          .attr("y", (d) => yScale(d.value))
          .attr("height", (d) => innerHeight - yScale(d.value))
          .attr("fill", (d) => colourScale(d.key));
 

        // Exit selection: remove any excess bars
        bars.exit().remove();
        bars.raise();
      }
    };

    handleResize()

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data]);

  return (
    <div
      className="mbchart"
      style={{
        backgroundColor: `${backgroundColour}`,
      }}
    >
      <ChartHeader title={title} />
      <ColourLegend
        keys={xMBArray}
        colourScale={colourScale}
        colourLegend={colourLegend}
        legendItem={legendItem}
        legendLabel={legendLabel}
      />
      <svg className="chartsvg" ref={parentRef}>
        <g className="bars" ref={svgRef}>
          <g className="x-axis" />
          <g className="y-axis" />
        </g>
      </svg>
    </div>
  );
};

export default MultiBarChart;