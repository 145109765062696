import React, { useContext } from 'react';
import Card from '../charts/Card.jsx';
import { DataContext } from '../contexts/DataContext.jsx';
import { sumValuesByDataPoint, formatNumber } from '../functions.js';

const HivCardBar = () => {
  const { state } = useContext(DataContext);
  const { filteredHivData } = state

  const positiveUnits = sumValuesByDataPoint(filteredHivData, 'Positive')
  const negativeUnits = sumValuesByDataPoint(filteredHivData, 'Negative')
  const totalTestsUnits = positiveUnits + negativeUnits

  const totalTestsRound = formatNumber(totalTestsUnits)
  const positiveRound = formatNumber(positiveUnits)
  let percentagePositive = "0.00%";

  if (!isNaN(positiveUnits) && !isNaN(totalTestsUnits) && totalTestsUnits > 0) {
    percentagePositive = ((positiveUnits / totalTestsUnits) * 100).toFixed(2) + "%";
  }

  const filler = 0
  const grey = "rgba(0,0,0,0.1)"

  return (
    <div className="hivcardbar">
      <Card
        subtitle="Total Tests"
        value={totalTestsRound}
        backgroundColour={grey}
      />
      <Card
        subtitle="Positive"
        value={positiveRound}
        backgroundColour={grey}
      />
      <Card
        subtitle="% Positive"
        value={percentagePositive}
        backgroundColour={grey}
      />
      <Card
        subtitle="Filler"
        value={filler}
        backgroundColour={grey}
      />
      <Card
        subtitle="Filler"
        value={filler}
        backgroundColour={grey}
      />
      <Card
        subtitle="Filler"
        value={filler}
        backgroundColour={grey}
      />
      <Card
        subtitle="Filler"
        value={filler}
        backgroundColour={grey}
      />
      <Card
        subtitle="Filler"
        value={filler}
        backgroundColour={grey}
      />
    </div>
  )
}

export default HivCardBar