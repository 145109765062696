import React, { useState, useEffect, useRef } from "react";

const MultiselectDropdown = ({
  id,
  label,
  options,
  placeholder,
  onChange,
  selectedValues,
  maxDisplayedItems = 3,
  multiselectClass,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("mousedown", handleMouseDown);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    const updatedSelectedValues = selectedValues.includes(option)
      ? selectedValues.filter((value) => value !== option)
      : [...selectedValues, option];

    onChange(updatedSelectedValues);
  };

  const getSelectedText = () => {
    if (selectedValues.length === 1) {
      return selectedValues[0];
    } else if (
      selectedValues.length > 1 &&
      selectedValues.length <= maxDisplayedItems
    ) {
      return selectedValues.join(", ");
    } else if (selectedValues.length > maxDisplayedItems) {
      return `${selectedValues.length} items selected`;
    }
    return "";
  };

  return (
    <div className={multiselectClass} ref={dropdownRef}>
      <label htmlFor={id}>{label}</label>
      <div className="dropdown">
        <div className="selected-values" onClick={handleToggle}>
          <div className="selected-text">
            {getSelectedText() || placeholder}
          </div>
          <div className="dropdown-arrow">&#9662;</div>
        </div>
        {isOpen && (
          <div className="options">
            <ul>
              {options.map((option) => (
                <li key={option} onClick={() => handleOptionClick(option)}>
                  <input
                    type="checkbox"
                    checked={selectedValues.includes(option)}
                    readOnly
                  />
                  {option}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiselectDropdown;
