import React, {useContext} from 'react'
import Card from '../charts/Card';
import { DataContext } from '../contexts/DataContext';
import { sumValuesByDataPoint, formatNumber } from '../functions';

const TuberculosisCardBar = () => {
  const { state } = useContext(DataContext);
  const { filteredTuberculosisData } = state

  // Card Data
  const testsUnits = sumValuesByDataPoint(filteredTuberculosisData, 'Tests')
  const diagnosedUnits = sumValuesByDataPoint(filteredTuberculosisData, 'Diagnosed')
  const casesUnits = sumValuesByDataPoint(filteredTuberculosisData, 'Cases')

  // Card Formatted
  const testsRound = formatNumber(testsUnits)
  const diagnosedRound = formatNumber(diagnosedUnits)
  const casesRound = formatNumber(casesUnits)

  // Extras
  const fillerText = "Filler"
  const filler = 0
  const grey = "rgba(0,0,0,0.1)"

  return (
    <div className='tuberculosiscardbar'>
      <Card 
        subtitle="Tests"
        value={testsRound}
        backgroundColour={grey}
      />
      <Card 
        subtitle="Diagnosed"
        value={diagnosedRound}
        backgroundColour={grey}
      />
      <Card 
        subtitle="Cases"
        value={casesRound}
        backgroundColour={grey}
      />
      <Card 
        subtitle={fillerText}
        value={filler}
        backgroundColour={grey}
      />
      <Card 
        subtitle={fillerText}
        value={filler}
        backgroundColour={grey}
      />
      <Card 
        subtitle={fillerText}
        value={filler}
        backgroundColour={grey}
      />
      <Card 
        subtitle={fillerText}
        value={filler}
        backgroundColour={grey}
      />
      <Card 
        subtitle={fillerText}
        value={filler}
        backgroundColour={grey}
      />
    </div>
  )
}

export default TuberculosisCardBar