import React, { useState, useEffect, useContext } from "react";
import { DataContext } from "../contexts/DataContext.jsx";
import MultiselectDropdown from "../components/MultiselectDropdown.jsx";
import SingleSelectDropdown from "../components/SingleSelectDropdown.jsx";
import { getUniqueValues, sortYears, sortMonths, sortAlphabetically } from "../functions.js";
import EpiData from "../data/EpiData.jsx";

const EpidemiologySlicerBar = () => {
  const { dispatch, state } = useContext(DataContext);
  const { epiData } = state

  // Selection State
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedLGAs, setSelectedLGAs] = useState([]);
  const [selectedDiseases, setSelectedDiseases] = useState('AFP');

  // Display State
  const [displayYears, setDisplayYears] = useState("");
  const [displayMonths, setDisplayMonths] = useState("");
  const [displayLGAs, setDisplayLGAs] = useState("");
  const [displayDiseases, setDisplayDiseases] = useState("");

  // Slicers
  const [yearSelectFilter, setYearSelectFilter] = useState([]);
  const [monthSelectFilter, setMonthSelectFilter] = useState([]);
  const [LGASelectFilter, setLGASelectFilter] = useState([]);
  const [diseaseSelectFilter, setDiseaseSelectFilter] = useState([]);


  // FILTERED DATA
  useEffect(() => {
    const newEpiData = epiData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const LGAMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);

      return yearMatch && monthMatch && LGAMatch;
    });

    dispatch({
      type: "UPDATE_FILTERED_EPI_DATA",
      payload: newEpiData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedLGAs,
    epiData,
  ]);

  // Disease Single Select Filter
  useEffect(() => {
    const newEpiDiseaseData = epiData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const LGAMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);
      const diseaseMatch =
        selectedDiseases.length === 0 ||
        selectedDiseases.includes(row.disease);

      return yearMatch && monthMatch && LGAMatch && diseaseMatch;
    });

    dispatch({
      type: "UPDATE_FILTERED_EPI_DISEASE_DATA",
      payload: newEpiDiseaseData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedLGAs,
    selectedDiseases,
    epiData,
  ]);

  // MULTISELECT FILTERS
  // Year
  useEffect(() => {
    const yearFilteredData = epiData.filter((row) => {
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const lgaMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);

      return monthMatch && lgaMatch;
    });

    setYearSelectFilter(yearFilteredData);
  }, [selectedMonths, selectedLGAs, epiData]);

  // Month
  useEffect(() => {
    const monthFilteredData = epiData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const lgaMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);

      return yearMatch && lgaMatch;
    });

    setMonthSelectFilter(monthFilteredData);
  }, [selectedYears, selectedLGAs, epiData]);

  // LGA
  useEffect(() => {
    const lgaFilteredData = epiData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);

      return yearMatch && monthMatch;
    });

    setLGASelectFilter(lgaFilteredData);
  }, [selectedYears, selectedMonths, epiData, dispatch]);

  // Disease
  useEffect(() => {
    const diseaseFilteredData = epiData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const lgaMatch =
        selectedLGAs.length === 0 ||
        selectedLGAs.includes(row.lga);

      return yearMatch && monthMatch && lgaMatch;
    });

    setDiseaseSelectFilter(diseaseFilteredData);
  }, [selectedYears, selectedMonths, selectedLGAs, epiData, dispatch]);

  // Select Functionality
  const handleYearSelect = (selectedList) => {
    setSelectedYears(selectedList);
    setDisplayYears(`${selectedList.length} options selected`);
  };

  const handleMonthSelect = (selectedList) => {
    setSelectedMonths(selectedList);
    setDisplayMonths(`${selectedList.length} options selected`);
  };

  const handleLGASelect = (selectedList) => {
    setSelectedLGAs(selectedList);
    setDisplayLGAs(`${selectedList.length} options selected`);
  };

  const handleDiseaseSelect = (selectedList) => {
    setSelectedDiseases(selectedList);
    setDisplayDiseases(`${selectedList.length} options selected`);
  };

  const SelectedCountDisplay = ({ selectedOptions }) => {
    const selectedCount = selectedOptions.length;
    return <div>{`${selectedCount} options selected`}</div>;
  };

  return (
    <>
    <EpiData />
    <div className="slicerbarEpi">
      <MultiselectDropdown
        label="Year"
        id="year"
        placeholder="Year"
        options={sortYears(getUniqueValues(yearSelectFilter, "year"))}
        selectedValues={selectedYears}
        onChange={handleYearSelect}
        multiselectClass='multiselect-dropdown'
      />
      <MultiselectDropdown
        label="Month"
        id="month"
        placeholder="Month"
        options={sortMonths(getUniqueValues(monthSelectFilter, "month"))}
        selectedValues={selectedMonths}
        onChange={handleMonthSelect}
        multiselectClass='multiselect-dropdown'
      />
      <MultiselectDropdown
        label="LGA"
        id="lga"
        placeholder="Local Government Area"
        options={sortAlphabetically(getUniqueValues(LGASelectFilter, "lga"))}
        selectedValues={selectedLGAs}
        onChange={handleLGASelect}
        multiselectClass='multiselect-dropdown'
      />
      <SingleSelectDropdown
        label="Disease"
        id="disease"
        placeholder="Disease"
        options={sortAlphabetically(getUniqueValues(diseaseSelectFilter, "disease"))}
        selectedValue={selectedDiseases}
        onChange={handleDiseaseSelect}
        dropdownClass='multiselect-dropdown'
      />
    </div>
    </>
  );
};

export default EpidemiologySlicerBar;
