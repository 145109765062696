import React from 'react'
import HivSlicerBar from '../HIV/HivSlicerBar'
import HivTitleBar from '../HIV/HivTitleBar'
import HivCardBar from '../HIV/HivCardBar'
import HivChartBar from '../HIV/HivChartBar'
import HivChartBar2 from '../HIV/HivChartBar2'

const Hiv = ({colourScale}) => {

  return (
    <div className='hivdashboard'>
      <HivSlicerBar />
      <HivTitleBar />
      <HivCardBar />
      <HivChartBar colourScale={colourScale}/>
      <HivChartBar2 colourScale={colourScale}/>
    </div>
  )
}

export default Hiv