import React from 'react'
import LasambusSlicerBar from '../Lasambus/LasambusSlicerBar'
import LasambusCardBar from '../Lasambus/LasambusCardBar'
import LasambusChartBar from '../Lasambus/LasambusChartBar'
import LasambusChartBar2 from '../Lasambus/LasambusChartBar2'

const Lasambus = () => {

  return (
    <div className='lasambusdashboard'>
      <LasambusSlicerBar />
      <LasambusCardBar />
      <LasambusChartBar />
      <LasambusChartBar2 />
    </div>
  )
}

export default Lasambus