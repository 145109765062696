import React, { useState, useEffect, useContext } from "react";
import { DataContext } from "../contexts/DataContext.jsx";
import { fetchInitData } from "../functions.js";
import { prodApi } from "./api.js";
import { useLoading } from "../contexts/LoadingContext.jsx";

// URLs
const url = prodApi.tuberculosisData;

// Full Data
const TuberculosisData = () => {
  const { dispatch } = useContext(DataContext);
  const { startLoading, stopLoading } = useLoading()

  const [tuberculosisData, setTuberculosisData] = useState([]);

  // FETCH AND SET DATA
  useEffect(() => {
    startLoading();
    fetchInitData(url, setTuberculosisData, 'lga')
      .then(() => {
        stopLoading();
      })
      .catch(() => {
        stopLoading();
      });
  }, []);

  useEffect(() => {
      dispatch({
        type: "TUBERCULOSIS_DATA",
        payload: tuberculosisData,
      });
  },[
      tuberculosisData,
  ])

  return null;
};

export default TuberculosisData;