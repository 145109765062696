import React from "react";

const LogoutButton = ({ handleLogout }) => {
  return (
    <div className="icon-container">
      <span className="eicon-logout" onClick={handleLogout} />
    </div>
  );
};

export default LogoutButton;
