import React from "react";

const ColourLegend = ({
  keys,
  colourScale,
  colourLegend,
  legendItem,
  legendLabel,
}) => {
  return (
    <div className={colourLegend}>
      {keys.map((key) => (
        <div key={key} className={legendItem}>
          <span
            className="color-box"
            style={{ background: colourScale(key) }}
          ></span>
          <span className={legendLabel}>{key}</span>
        </div>
      ))}
    </div>
  );
};

export default ColourLegend;
