import React, {useContext} from 'react'
import { DataContext } from '../contexts/DataContext'
import CardTitle from '../components/CardTitle'
import { getUniqueValues } from '../functions'

const EpidemiologyTitleBar = () => {
    const { state } = useContext(DataContext);
    const { filteredEpiDiseaseData } = state

    const title1 = "DISEASES (CASES)"
    const title2 = getUniqueValues(filteredEpiDiseaseData, 'disease')

      return (
          <div className="epititlebar">
            <CardTitle cardTitle={title1}/>
            <CardTitle cardTitle={title2}/>
          </div>
        );
}

export default EpidemiologyTitleBar