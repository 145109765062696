import React from "react";
import Card from "../charts/Card.jsx";

const LasambusCardBar = () => {

  // // Card Calculations Round Up
  const validCallsRound = "0"
  const emergenciesRound = "0"
  const peopleInvolvedRound = "0"
  const peopleRescuedRound = "0"
  const averageResponseTimeRound = "0"
  const deathsInTransitRound = "0"
  const deathsOnArrivalRound = "0"
  const totalDeathsRound = "0"

  const grey = "rgba(0,0,0,0.1)"

  return (
    <div className="cardbar">
      <Card
        subtitle="Number of Valid Calls"
        value={validCallsRound}
        backgroundColour={grey}
      />
      <Card
        subtitle="Number of Emergencies"
        value={emergenciesRound}
        backgroundColour={grey}
      />
      <Card
        subtitle="Number of People Involved"
        value={peopleInvolvedRound}
        backgroundColour={grey}
      />
      <Card
        subtitle="Number of People Rescued"
        value={peopleRescuedRound}
        backgroundColour={grey}
      />
      <Card
        subtitle="Average Response Time"
        value={averageResponseTimeRound}
        backgroundColour={grey}
      />
      <Card
        subtitle="Deaths in Transit"
        value={deathsInTransitRound}
        backgroundColour={grey}
      />
      <Card
        subtitle="Deaths on Arrival at Facility"
        value={deathsOnArrivalRound}
        backgroundColour={grey}
      />
      <Card
        subtitle="Total Deaths"
        value={totalDeathsRound}
        backgroundColour={grey}
      />
    </div>
  );
};

export default LasambusCardBar;
