import React, { useContext, useEffect, useState } from "react";
import * as d3 from "d3";
import * as topojson from "topojson-client"; 
import { DataContext } from "../contexts/DataContext.jsx";
import MapChart from "../charts/MapChart.jsx";
import { getUniqueValues } from "../functions.js";
import lgLGAs from "../data/lgLGAs.topojson"

const TuberculosisChartBar2 = () => {
  const { state } = useContext(DataContext);
  const { filteredTuberculosisDatapointData } = state;

  const [mapData, setMapData] = useState(null)
  const datapoint = getUniqueValues(filteredTuberculosisDatapointData, 'datapoint')

  // Set LGA Data
  useEffect(() => {
    d3.json(lgLGAs).then(topojsonData => {
      const {LGAs} = topojsonData.objects;
      setMapData(topojson.feature(topojsonData, LGAs))
    })
  }, [])

  // Map Chart
  const mapChartTitle = ""
  const mapColour = "#74B72E"
  const mapHoveredColour = "#466D1D"

  const grey = "rgba(0,0,0,0.1)"

  return (
    <div className="tuberculosischartbar2">
      <MapChart
        title={mapChartTitle}
        backgroundColour={grey}
        data={filteredTuberculosisDatapointData}
        mapData={mapData}
        mapColour={mapColour}
        mapHoveredColour={mapHoveredColour}
        sub='lga'
        minColour='white'
        maxColour="#960018"
        valueTitle={datapoint}
      />
    </div>
  )
}

export default TuberculosisChartBar2